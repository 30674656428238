import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Chip, Container, Grid } from '@mui/material';
import WebContentCard from '../card/web-content-card';
import IconCard from '../card/icon-card';

import "./style.scss";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BusinessCard from '../card/business-card';
import { useEffect, useRef, useState } from 'react';
  
/**
 * Implement Carousel with touch move
 * 
 * @param props 
 * @returns 
 */
const Carousel = (props: React.PropsWithChildren<any>) => {

const MOVE_THRESHOLD = 50; 

const items = props.items;

const [touchStart, setTouchStart] = React.useState(0);
const [touchEnd, setTouchEnd] = React.useState(0);

const theme = useTheme();
const [activeStep, setActiveStep] = React.useState(0);


//let track = useRef({ index: 0, top: 0, left: 0 });
//const unitW = 1000;
//const viewW = 1200;
//const spaceW = (viewW - unitW)/2; // space width for the first and last div

const spaceW = 10; 

const itemRefs = useRef([] as HTMLDivElement[]);

//const tracks = [] as any;
for(let i = 0; i < items.length; i++) {
    //tracks.push({ index: i, top: 0, left: 0 });
    //itemRefs[i] = useRef<HTMLDivElement>(null);
}

let track = useRef({left: 0}); 


//const maxSteps = steps.length;
const maxSteps = items.length;

let [timer, setTimer] = useState(-1);
const timeStep = 1; 

const step = 10; // pixels to move for each scroll

let move = 0;

const scroll = (step: number, distance: number) => {

    if(Math.abs(move) < distance) {   
        track.current.left += step; 
        move += step; 
        let newTimer: any = setTimeout(() => {
            scroll(step, distance);
        }, timeStep);
        setTimer(newTimer);
    } else {
        clearTimeout(timer); 
        track.current.left += step*(distance - Math.abs(move))/Math.abs(step); // to correct the error caused by difference
        move = 0; 
    }

}

const handleNext = () => {

    clearTimeout(timer);
    if(activeStep < maxSteps - 1) {

        //scroll(-step, track.current.left - unitW); 
        scroll(-step, itemRefs.current[activeStep].clientWidth); 
        setActiveStep(activeStep + 1);

    }
};

const handleBack = () => {
    clearTimeout(timer);
    if(activeStep > 0) {

        //scroll(step, track.current.left + unitW); 
        scroll(+step, itemRefs.current[activeStep-1].clientWidth); 
        setActiveStep(activeStep - 1);

    }
};


const handleTouchStart = (e: any) => {
    if(e.targetTouches && e.targetTouches.length > 0) {
        setTouchStart(e.targetTouches[0].clientX);
    } 
    //setTouchStart(e.targetTouches[0].clientX);
}

const handleTouchMove = (e: any) => {
    if(e.targetTouches && e.targetTouches.length > 0) {
        setTouchEnd(e.targetTouches[0].clientX);
    }
    //setTouchEnd(e.targetTouches[0].clientX);

}

const handleTouchEnd = () => {
    if (touchStart - touchEnd > MOVE_THRESHOLD) {
        // do your stuff here for left swipe
        //moveSliderRight();
        handleBack();
        console.log("left swipe");
    }

    if (touchStart - touchEnd < -MOVE_THRESHOLD) {
        // do your stuff here for right swipe
        //moveSliderLeft();
        handleNext();
        console.log("right swipe");
    }
}

useEffect(() => {
}, []);


return (
<div className="carousel-wrapper">
<div className="carousel"  
/**
    onTouchStart={(e: any)=> {
        //handleTouchStart(e); 
        console.log("touch start: " + e.clientX);
    }}
    onTouchMove={(e: any)=> {
        //handleTouchMove(e); 
        console.log("touch move: " + e.clientX);
    }}
    onTouchEnd={()=> {
        //handleTouchEnd(); 
        console.log("touch end: ");
    }}
    // mouse events
    onMouseLeave={()=> {handleTouchEnd();
        console.log("mouse leave");
    }}
    onMouseDown={(e: any)=> {
        //handleTouchStart(e); 
        console.log("mouse down: " + e.clientX); 
        //setTouchStart(e.clientX); 
    }}
    onMouseMove={(e: any)=> {handleTouchMove(e); 
        console.log("mouse move: " + e.clientX);
        //setTouchEnd(e.clientX);
    }}
    onMouseUp={()=> {
        //handleTouchEnd(); 
        console.log("mouse up");
        if (touchStart - touchEnd > MOVE_THRESHOLD) {
            handleNext();
            console.log("left swipe");
        } else if (touchStart - touchEnd < -MOVE_THRESHOLD) {
            handleBack();
            console.log("right swipe");
        }
        setTouchStart(0);
        setTouchEnd(0);
    }}
**/        
>

<Box flexGrow={1} sx={{border: "0px solid red", backgroundColor: "white", zIndex: 200}}></Box>

<Box sx={{}}>
    
<Container maxWidth="lg">
    <div className="center">
    <Grid
        style={{
            position: "relative",
            top: 0,
            left:  track.current.left ,
            padding: "0px",
            margin: "0px",
            border: "0px solid blue",
            opacity: 1.0,
            width: "auto",
            alignContent: "center",
            textAlign: "center",
        }}
        container
        wrap="nowrap"
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        alignContent="center"
        spacing={0}
        columnGap={0}
    >
        {items.map((item: any, index: number) => {
            return (
                <Grid container item xs={12} 
                    justifyContent="center"
                    textAlign="center"
                    key={index}
                    ref={(el) => { itemRefs.current[index] = el as HTMLDivElement;}}
                    style={{
                    position: "relative",
                    opacity: item.opacity,
                    border: "0px solid maroon",
                    padding: 10,
                    marginLeft: 0,
                    marginRight: 0,
                    }}
                >
                    <BusinessCard business={item} key={`business-${index}`} />
                </Grid>
            );
        })}
    </Grid>
    </div>
</Container>


</Box>


<Box flexGrow={1} sx={{border: "0px solid red", backgroundColor: "white", zIndex: 200}}></Box>
</div>



<Box className="stepper center">

<Container maxWidth="lg">
<MobileStepper
    variant="dots"
    steps={maxSteps}
    position="static"
    activeStep={activeStep}
    sx={{ flexGrow: 1, width: "100%" }}
    nextButton={
    <Button variant="outlined" size="small" color="secondary" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
        Next
        {theme.direction === 'rtl' ? (
        <KeyboardArrowLeft />
        ) : (
        <KeyboardArrowRight />
        )}
    </Button>
    }
    backButton={
    <Button variant="outlined" size="small" color="secondary" onClick={handleBack} disabled={activeStep === 0}>
        {theme.direction === 'rtl' ? (
        <KeyboardArrowRight />
        ) : (
        <KeyboardArrowLeft />
        )}
        Back
    </Button>
    }
/>

</Container>

</Box>

</div>

);

}; 


export default Carousel;


