import Amplify, { Auth } from "aws-amplify";

import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import "react-phone-number-input/style.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";

import "./style.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../context/user-context";
import { useNavigate, useParams } from "react-router-dom";
import Banner from "../components/banner/banner";
import { BSGAPI } from "../auth/bsg-api";
import { DFormValidation, DWebContent } from "../model";
import BPhoneField from "../components/form/phone-field";
import { ContextService } from "../context/context-service";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// You can get the current config object
const currentConfig = Auth.configure();

const SignUp = (props: any) => {
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const history = useNavigate();

  let myRef = useRef<HTMLTextAreaElement>(null);

  const { myStep } = useParams();

  const [step, setStep] = useState(
    //props.match.params.step ? props.match.params.step : 0
    myStep? myStep : 0
  );

  //const [error, setError] = useState({ name: "", code: "" });
  const [error, setError] = useState("");

  const [code, setCode] = useState("");

  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false); // track if the form is touched by user.
  const [emailValidation, setEmailValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);

  const [phone, setPhone] = useState("");
  const [phoneTouched, setPhoneTouched] = useState(false); // track if the form is touched by user.
  const [phoneValidation, setPhoneValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [passwordTouched, setPasswordTouched] = useState(false); // track if the form is touched by user.
  const [passwordValidation, setPasswordValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);
  const [passwordAgainValidation, setPasswordAgainValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const signup = () => {

    let phoneDigits = phone.replace(/\D/g, "");

    console.log("phone digits: " + phoneDigits);

    (context.cognitoSignup(email, `+1${phoneDigits}`, password) as Promise<any>)
      .then((json) => {
        /**
                   * Sample success response
                   * 
                   {
                      "user":
                      {
                        "username":"sinotar4",
                        "pool":
                        {
                          "userPoolId":"us-east-1_0EVwHFPm0",
                          "clientId":"7m39ip0e8kdbtvodk1o04vo0jm",
                          "client":
                          {
                            "endpoint":"https://cognito-idp.us-east-1.amazonaws.com/",
                            "fetchOptions":{}
                          },
                          "advancedSecurityDataCollectionFlag":true,"storage":{"CognitoIdentityServiceProvider.5fel06v2t04quptp2ueg3v8413.julian6866.userData":"{\"UserAttributes\":[{\"Name\":\"sub\",\"Value\":\"de4f49e5-d6b8-4e1b-b268-1677c3109bd1\"},{\"Name\":\"email_verified\",\"Value\":\"true\"},{\"Name\":\"phone_number_verified\",\"Value\":\"false\"},{\"Name\":\"phone_number\",\"Value\":\"+16178636274\"},{\"Name\":\"email\",\"Value\":\"julian6866@gmail.com\"}],\"Username\":\"julian6866\"}","_AMap_labelcanvas":
                          ................
                          ................
                          "subkey:\\\"16\\\"},StyleItem:[{$:{level:\\\"15,16,17,18,19,20\\\",desc:\\\"99,0xffdbe8b3,
                          CfzC}}?y?8)}1/1y}},}S}p1}}o_o3})1}}},}S}p1/7p17pCnccbC,l\\\\\\\"dgd(CfC???}?_?wCbCu}d]{(uoau[)Cfypcn\\\"\"}"},
                          "keyPrefix":"CognitoIdentityServiceProvider.7m39ip0e8kdbtvodk1o04vo0jm","userDataKey":"CognitoIdentityServiceProvider.7m39ip0e8kdbtvodk1o04vo0jm.sinotar4.userData"},"userConfirmed":false,"userSub":"d152c72a-b90f-4059-aadc-89256ce6e309","codeDeliveryDetails":{"AttributeName":"email","DeliveryMedium":"EMAIL","Destination":"s***@g***.com"}}
                   */
        //console.log(user + JSON.stringify(user));
        console.log("Json: " + JSON.stringify(json));

        if (json.status == "ok") {
          setStep(2);
        } else {
          setError(json.err);
          setStep(1);
        }
      })
      .catch((err) => {
        /**
                   * Sample error response
                   {
                      "code":"UsernameExistsException",
                      "name":"UsernameExistsException",
                      "message":"User already exists"
                    }
                   */
        //console.log("Error: " + JSON.stringify(err));

        setError(err);
        setStep(1);
      });
  };

  const confirmSignup = () => {
    //console.log("old/new: " + oldPassword + ":" + newPassword);
    context
      .cognitoConfirmSignup(email, code)
      .then((json) => {
        //console.log("data: " + JSON.stringify(data));
        //if (data == "SUCCESS") {
        //setStep(3);
        //}

        if (json.status == "ok") {
          setStep(3);
        } else {
          setError(json.err);
          setStep(1);
        }

      })
      .catch((err) => {
        //console.log("err: " + err);
        setStep(1);
      });
  };

  const test = (val: string) => {
    let masked = BSGAPI.mask("(DDD) DDD-DDDD", val);
    console.log("masked: " + JSON.stringify(masked));
    setPhone(masked.value);
    myRef.current?.setAttribute("value", masked.value);
    myRef.current?.setSelectionRange(masked.cursor, null);
    myRef.current?.focus();
  };

  useEffect(() => {
    document.title = `${ContextService.SITE_NAME}:Sign Up`;
    BSGAPI.getWebContent("sign-up").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="md">
          {(step == 0 || step == 1) && (
            <Grid container spacing={4}>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <EmailIcon color="secondary" />{" "}
                <TextField
                  required
                  id="outlined-required"
                  color="secondary"
                  label="Email"
                  variant="outlined"
                  defaultValue={email}
                  error={!emailValidation.isValid}
                  helperText={emailValidation.err}
                  onChange={(e) => {
                    setEmailTouched(true);
                    setEmail(e.target.value);
                    setEmailValidation(
                      BSGAPI.validate(
                        BSGAPI.RegExp_EMAIL,
                        "email",
                        e.target.value,
                        null
                      )
                    );
                  }}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <PhoneAndroidIcon color="secondary" />
                <TextField
                  inputRef={myRef}
                  required
                  id="outlined-required"
                  color="secondary"
                  label="Phone"
                  variant="outlined"
                  error={!phoneValidation.isValid}
                  helperText={phoneValidation.err}
                  defaultValue={phone}
                  value={BSGAPI.format(phone) }
                  onSelect={(e: any) => {}}
                  onKeyPress={(e: any) => {
                    //e.preventDefault();
                    //if (!BSGAPI.RegExp_PHONE_ALLOWED_INPUT.test(e.key)) {
                    //  e.preventDefault();
                    //}
                  }}
                  onChange={(e) => {
                    if (!BSGAPI.RegExp_PHONE_ALLOWED_INPUT.test(e.target.value)) {
                      e.preventDefault();
                    }
                    setPhoneTouched(true);
                    setPhone(e.target.value);
                    setPhoneValidation(
                      BSGAPI.validate(
                        BSGAPI.RegExp_PHONE,
                        "phone",
                        BSGAPI.format(e.target.value),
                        "10-digit phone number (US)"
                      )
                    );
                  }}
                />{" "}

              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <VpnKeyIcon color="secondary" />{" "}
                <TextField
                  required
                  id="outlined-password-input"
                  color="secondary"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  variant="outlined"
                  error={!passwordValidation.isValid}
                  helperText={passwordValidation.err}
                  defaultValue={password}
                  onChange={(e) => {
                    //setTouched(true);
                    setPasswordTouched(true);
                    setPassword(e.target.value);
                    setPasswordValidation(
                      BSGAPI.validate(
                        BSGAPI.RegExp_PASSWORD,
                        "password",
                        e.target.value,
                        "Password minimum length: 6"
                      )
                    );
                    setPasswordAgainValidation({
                      isValid: passwordAgain == e.target.value,
                      err:
                        passwordAgain == e.target.value
                          ? ""
                          : "Password does not match",
                    });
                  }}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <VpnKeyIcon color="secondary" />{" "}
                <TextField
                  required
                  type="password"
                  id="outlined-required"
                  variant="outlined"
                  color="secondary"
                  label="Password (again)"
                  error={!passwordAgainValidation.isValid}
                  helperText={passwordAgainValidation.err}
                  defaultValue={password}
                  onChange={(e) => {
                    //setTouched(true);
                    //setPasswordTouched(true);
                    //setPassword(e.target.value);

                    setPasswordAgain(e.target.value);
                    setPasswordAgainValidation({
                      isValid: password == e.target.value,
                      err:
                        password == e.target.value
                          ? ""
                          : "Password does not match",
                    });
                  }}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h4>
                  * By signing up, you must agree with our Business User
                  Agreement
                </h4>
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {step == 1 && (
                  <h3 className="warning-dark">
                    Something is wrong. This is all we know. 
                    <br />
                    {error }
                  </h3>
                )}

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => signup()}
                  disabled={
                    !emailTouched ||
                    !emailValidation.isValid ||
                    !phoneTouched ||
                    !phoneValidation.isValid ||
                    !passwordTouched ||
                    !passwordValidation.isValid ||
                    !passwordAgainValidation.isValid
                  }
                >
                  Sign up
                </Button>
              </Grid>
            </Grid>
          )}

          {step == 2 && (
            <Grid container spacing={4}>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>A verification code has been sent to your email.</h2>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <EmailIcon color="secondary" />{" "}
                <TextField
                  required
                  id="outlined-required"
                  variant="outlined"
                  label="Your email"
                  defaultValue={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <VpnKeyIcon color="secondary" />{" "}
                <TextField
                  required
                  id="outlined-required"
                  variant="outlined"
                  label="Code"
                  defaultValue={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1></h1>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => confirmSignup()}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          )}

          {step == 3 && (
            <Grid container spacing={4}>
              <h2 className="center">You are all set!</h2>

              <h2 className="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history("/sign-in")}
                >
                  Sign in
                </Button>
              </h2>
            </Grid>
          )}

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <h4>
                <Link to="/sign-in">Sign In? </Link> |
                <Link to="/forgot-password"> Forgot password? </Link> |
                <Link to="/forgot-password/1"> I have code </Link>
              </h4>
            </Grid>
          </Grid>
        </Container>{" "}
      </div>

      {webContent.faqs && (
        <div className="section-white">
          <Container maxWidth="md">
            <div>
              <h2>FAQs</h2>
            </div>
            {webContent.faqs?.map((question, index) => (
              <Accordion key={`faq-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h2 className="secondary-light">{question.question}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<h3>${question.answer}</h3>`,
                    }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </div>
      )}
    </div>
  );
};

export default SignUp;
/**
 * 
 * 
 * 
 

 CognitoUser {username: "sinotar", pool: CognitoUserPool, Session: null, client: Client, signInUserSession: null, …}

 */
