import {
  Badge,
  Button,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/user-context";

import MenuIcon from "@mui/icons-material/Menu";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import MailIcon from "@mui/icons-material/Mail";
import MoreIcon from "@mui/icons-material/More";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FlareIcon from "@mui/icons-material/Flare";
import { AccountCircle, BusinessCenter } from "@mui/icons-material";
import { styles } from "../../theme/Theme";
import "./style.scss";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import SchoolIcon from "@mui/icons-material/School";
import AppsIcon from "@mui/icons-material/Apps";
import { DNameValue } from "../../model";

const MobileMenu = (props: any) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  const { t, i18n } = useTranslation("content");

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const [email, setEmail] = useState("");

  const theme = useTheme();
  const classes: any = styles(theme);

  const history = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const [ui, setUi] = useState([] as string[]); 
  const routeTo = (route: string) => {
    history(route);
    handleMenuClose();
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";

  //useEffect(() => {
  //  setEmail(context.getEmail() as string);
  //}, [contextUpdate]);

  useEffect(() => {

    context.init().then(() => {
      if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
        setUi(context.user.ui as string[]); 
        setEmail(context.getEmail() as string);
        console.log("Email: ---->: " + context.getEmail());
      } else {
        setUi([]);
        setEmail("");
      }
    });
  }, [forceUpdate]);


  return (
    <>
      <div className="section">
        <IconButton
          className="navButton"
          aria-label="profile"
          aria-haspopup="true"
          color="inherit"
          onClick={handleProfileMenuOpen}
        >
          <AccountCircle />
          {email}
        </IconButton>
      </div>

      <Menu
        elevation={0}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        id={menuId}
        keepMounted={false}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            routeTo("/get-started");
          }}
        >
          Get Started
        </MenuItem>
        {context.user.cognitoUser?.Username ? (
          <>
          { ui.includes("mobile_profile")
          && 
          <MenuItem
          onClick={() => {
            routeTo("/profile");
          }}
        >
          My Profile
        </MenuItem>
        }
          <MenuItem
            onClick={() => {
              routeTo("/sign-out");
            }}
          >
            Sign out
          </MenuItem>
        </>
        ) : (
          <MenuItem
            onClick={() => {
              routeTo("/sign-in");
            }}
          >
            {" "}
            Sign In
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MobileMenu;
