import React, { PropsWithChildren } from "react";
import "./style.scss";

import YouTubeCard from "./youtube-card";
import GoogleSlideCard from "./google-slide-card";
import ImageCard from "./image-card";
import { DMedia } from "../../model";

const MediaCard = (props: PropsWithChildren<{ media: DMedia }>) => {
  const routeTo = (route: string) => {
    //history.push(route);
  };

  const media = props.media as DMedia;

  switch (media.type) {
    case "youtube":
      return (
        <YouTubeCard
          video={media.url}
          title={media.title!}
          description={media.description!}
        />
      );
    case "google-slide":
      return (
        <GoogleSlideCard
          src={media.url}
          title={media.title!}
          description={media.description!}
        />
      );
    case "image":
      return (
        <ImageCard
          src={media.url}
          title={media.title!}
          description={media.description!}
        />
      );
    default:
      return <h1>{media.title}</h1>;
  }
};

export default MediaCard;
