import { Chip, Container, Grid, IconButton, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BSGAPI } from "../../auth/bsg-api";
import { UserContext } from "../../context/user-context";
import { DNameValue, DPersonAttribute } from "../../model";
import Banner from "../banner/banner";
import NameValueCard from "./name-value-card";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BTextField from "../form/text-field";
import Candidate from "../../my/my-candidate";
import { ConsoleLogger } from "@aws-amplify/core";

import MuiAlert, { AlertProps } from "@mui/material/Alert";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const PersonAttribute = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [attributes, setAttributes] = useState([] as DNameValue[]);

  let [edit, setEdit] = useState(false);

  const person = props.person;
  const type = props.type;

  const [showMessage, setShowMessage] = useState(false);
  const [showError, setShowError] = useState(false);

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  let [key, setKey] = useState(new Date().toString());

  const getAttributeValue = (name: string) => {
    let attribute = attributes.find(
      (attr: DNameValue) => attr.Name == name
    ) as DNameValue;
    if (attribute != null) return attribute.Value;
  };

  const setAttribute = (name: string, value: string) => {
    let attribute = { Name: name, Value: value };

    let newAttributes = attributes.map((attr) => {
      return attr.Name == name ? attribute : attr;
    });
    let myAttribute = newAttributes.find(
      (attr: DNameValue) => attr.Name == name
    ) as DNameValue;
    if (myAttribute == null) {
      newAttributes.push(attribute);
    }
    setAttributes(newAttributes);
  };

  const updateMyAttributes = () => {
    BSGAPI.updatePersonAttributes(
      context.user.cognitoCredentials,
      context.user.cognitoUser,
      person,
      type,
      attributes
    ).then((json) => {
      console.log("JSON: " + JSON.stringify(json));
      if (json.status == "ok") {
        //setAttributes(json.data.attributes as DNameValue[]);
        //console.log("OK OK OK OK ...");
        setMessage("Saved");
        setShowMessage(true);
      } else if (json.status == "token-expired") {
        setError("Your session has expired. Please login again.");
        setShowError(true);
        setTimeout(() => {
          history("/sign-in?flow=REFRESH_TOKEN_AUTH");
        }, 3000);
      } else {
        // TODO: exception handling ..

        setError("Some error occurred. Please try again later.");
        setShowError(true);
      }
    });
  };

  useEffect(() => {
    document.title = "BSG Partner:Persoon Profile";

    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        BSGAPI.getPersonAttributes(
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          person,
          type
        ).then((json) => {
          if (json.status == "ok") {
            setAttributes(json.data.attributes as DNameValue[]);
          } else if (json.status == "token-expired") {
            setError("Your session has expired. Please login again.");
            setShowError(true);
            setTimeout(() => {
              history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            }, 3000);
          } else {
            // TODO: exception handling ..
            //console.log("error deleting ...");
          }
        });
        forceUpdate();
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/profile`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            {attributes.map((attribute, index) => {
              return (
                <>
                  {edit ? (
                    <Grid
                      key={`grid-${key}-${index}`}
                      container
                      item
                      xs={3}
                      rowSpacing={4}
                      justifyContent="center"
                      alignContent="center"
                    >
                      <TextField
                        key={`name-${key}-${index}`}
                        fullWidth
                        label="Name"
                        name="Name"
                        defaultValue={attribute.Name as string}
                        onChange={(e: any) => {
                          let newAttributes = attributes;
                          newAttributes[index].Name = e.target.value;
                          setAttributes(newAttributes);
                        }}
                      ></TextField>
                      <TextField
                        key={`value-${key}-${index}`}
                        label="Value"
                        name="Value"
                        fullWidth
                        defaultValue={attribute.Value as string}
                        onChange={(e: any) => {
                          let newAttributes = attributes;
                          newAttributes[index].Value = e.target.value;
                          setAttributes(newAttributes);
                        }}
                      ></TextField>
                      <div>
                        <IconButton
                          color="warning"
                          aria-label="edit"
                          component="span"
                          onClick={() => {
                            //setEdit(true);
                            let newAttributes = attributes;
                            console.log("Lengtyh: " + newAttributes.length);
                            newAttributes.splice(index, 1);
                            console.log("New Lengtyh: " + newAttributes.length);
                            setAttributes(newAttributes);
                            setKey(new Date().toString());
                          }}
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </div>
                    </Grid>
                  ) : (
                    <div>
                      <Chip
                        label={`${attribute.Name}: ${attribute.Value}`}
                        variant="outlined"
                      />
                    </div>
                  )}
                </>
              );
            })}

            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignContent="center"
            >
              {showMessage && (
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  onClose={() => {
                    setShowMessage(false);
                  }}
                  severity="success"
                >
                  {message}
                </MuiAlert>
              )}
              {showError && (
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  onClose={() => {
                    setShowError(false);
                  }}
                  severity="error"
                >
                  {error}
                </MuiAlert>
              )}
            </Grid>
            {edit ? (
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignContent="center"
              >
                <IconButton
                  color="secondary"
                  aria-label="edit"
                  component="span"
                  onClick={() => {
                    updateMyAttributes();
                    setEdit(false);
                  }}
                >
                  <CheckCircleIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  aria-label="edit"
                  component="span"
                  onClick={() => {
                    //updateMyAttributes();
                    //setEdit(false);
                    setAttributes([...attributes, { Name: "name", Value: "" }]);
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  aria-label="edit"
                  component="span"
                  onClick={() => {
                    //updateMyAttributes();
                    setEdit(false);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
            ) : (
              <IconButton
                color="secondary"
                aria-label="edit"
                component="span"
                onClick={() => {
                  setEdit(true);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default PersonAttribute;
