import React, { PropsWithChildren, useContext, useState } from "react";
import "./style.scss";

import YouTubeCard from "./youtube-card";
import GoogleSlideCard from "./google-slide-card";
import ImageCard from "./image-card";
import { DCalendarEvent } from "../../model";
import { Button, CardActions, Container, Grid, TextField } from "@mui/material";

import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import PopWrapper from "../form/pop-wrapper";
import { BSGAPI2 } from "../../auth/bsg-api2";
import { UserContext } from "../../context/user-context";
import { useNavigate } from "react-router-dom";
import Loading from "../loading/loading";
import Multiline from "./multiline";

const EventCard = (props: PropsWithChildren<{ event: DCalendarEvent }>) => {
  const routeTo = (route: string) => {
    //history.push(route);
  };
  
  const navigate = useNavigate();

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const event = props.event as DCalendarEvent;

  const [loading, setLoading] = useState(false);
  
  const [showPop, setShowPop] = useState(false); 

  const [success, setSuccess] = useState(false); 

  const signUpEvent = () => {

    setLoading(true);

    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.username);

        forceUpdate();

        setLoading(true);

        // loading BSG Events
        BSGAPI2.signUpEvent(context.user.cognitoCredentials,
          {
            calendarID: event.calendarID,
            eventID: event.eventID
          }
        ).then((response: any) => {

          if(response.status == "ok") {
            console.log(response.data);
            setLoading(false);
            setSuccess(true);

          } else if (response.status == "token-expired") {
            navigate("/sign-in?flow=REFRESH_TOKEN_AUTH");
          } else {
          }
        })

      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        navigate("/sign-in");
      }
    });

  }

  return (

    <div className="event-card">
<Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            item
            xs={12}
            md={4}
            justifyContent="center"
            alignItems="center"
            justifyItems="center"
            alignContent="flex-start"
          >

        <img src="https://my-bsg-asset.s3.amazonaws.com/image/pic_customer.jpg" style={{maxWidth: "300px", width: "auto", paddingTop: "20px"}}/>

          </Grid>


          <Grid
            item
            xs={12}
            md={8}
            justifyContent="flex-start"
            alignItems="flex-start"
            textAlign="left"
          >

        <div className="title left">{event.name}</div>
        <div className="text left"><Multiline string={event.description} /></div>
        
        
        {event.url ? (
        <CardActions>
          <div className="action-button">
            <Button
              variant="outlined"
              size="medium"
              color="secondary"
              startIcon={<ArrowForwardIosOutlinedIcon />}
              onClick={() => {
                //routeTo(route);
              }}
            >
              Go
            </Button>
          </div>
        </CardActions>
      ) : (
        ""
      )}

      <Button variant="contained" color="secondary" 
        onClick={()=>{
          setShowPop(true);
        }}
      >Sign Up</Button>

          </Grid>
          </Grid>




          {showPop ? (

<PopWrapper
  onCancel={()=>{ setShowPop(false);}}
>
 
<Container maxWidth="md">
      <div className="event-card-signup">
          <Grid container spacing={3}>

{ success? 
(
  <>
 <Grid item xs={12}>

 <div className="content center">

<h2>
You have successfully signed up for this event. </h2>

<h2>
We will see you soon!
</h2>
 </div>
 </Grid>
   <Grid item xs={12}
     textAlign="center"
    >
      <Button
        variant="contained"
        color="warning"
        onClick={()=>{
          setShowPop(false);}}
      >
        Close
      </Button>
    </Grid>
 </>
)
: 
            
            (<>
            
            {loading ? (
              <Grid item xs={12}>
                <div className="content center">
                 <Loading show={loading}/>
                </div>
              </Grid>
            ) : (
                  <>
                    <Grid item xs={12}>
                      <div className="content center">
                    
                    
                    You are about to sign up with this event: 


                <div className="title center">{event.name}</div>
                <div className="text center">{event.description}</div>

                E-mail: { context.getEmail() }

        <div className="content left">
        <h2>Note: </h2>
        <ul>
        <li>We will send a calendar inviration to your email: {context.getEmail() } </li>
        <ul>
          <li>Please check your email and/or calendar to make sure you have received the invitation. </li>
          <li>Please check your spam mail folder in case the email was filtered and misplaced in the spam folder. </li>
        </ul>
        <li>You will receive a calendar invite from our service account: 
        service-account@bsg-academy.iam.gserviceaccount.com
        </li>
        <ul>
        <li>
        To avoid delay or email being possibly put into your spam email folder by some email service provider, please add the email to your contact. 
        </li>
        </ul>
        </ul>
        </div>


                      </div>
                    </Grid>

                    <Grid item xs={6} 
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    justifyItems="flex-end"
                    alignContent="flex-end"
                    textAlign="right"

                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={()=>{
                          signUpEvent();
                        }}
                      >
                        Submit
                      </Button>
                    </Grid><Grid item xs={6}
                    
                    textAlign="left"
                    >
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={()=>{
                          setShowPop(false);}}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
            )}
            </>
        )
      }
          </Grid>
      </div>
    </Container>

</PopWrapper>

  ) : (
    <></>
  )}


          </div>
  );
  
};

export default EventCard;
