import {
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import "./style.scss";

const PopWrapper = (props: React.PropsWithChildren<any>) => {

  const { children, window } = props;

  return (
    <div className="pop-wrapper">
      <Container maxWidth="md">
        <div className="pop-wrapper-content">
          <Grid
            container
            spacing={3}
            rowSpacing={6}
            style={{ justifyContent: "center" }}
          >
          { props.children }
          </Grid>
        </div>
      </Container>
    </div>
  );
}


export default PopWrapper;