import * as React from "react";
import {
  useTheme,
  useMediaQuery,
  Stack,
  Paper,
  Divider,
  Fab,
} from "@mui/material";
import Box from "@mui/material/Box";
import { BSGAPI } from "../../auth/bsg-api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MilestoneItem from "./milestone-item";
import { DLog, DPerson } from "../../model";
import { UserContext } from "../../context/user-context";
import { useEffect, useRef, useState } from "react";

export default function HiringTracker(props: any) {
  const { context, contextUpdate, forceUpdate } = React.useContext(UserContext);
  const theme = useTheme();
  const orientation = useMediaQuery(theme.breakpoints.up("md"))
    ? "horizontal"
    : "vertical";

  const alternativeLabel = useMediaQuery(theme.breakpoints.up("md"));

  const isStepFailed = (step: number) => {
    return step === 1;
  };

  const [key, setKey] = useState(new Date().toString());

  const tracker = useRef({
    candidate: props.candidate as DPerson,
    milestones: [] as DLog[],
  });

  //const [milestones, setMilestones] = useState([] as DLog[]);
  //const [candidate, setCandidate] = useState(props.candidate as DPerson);

  const onSave = (savedLog: DLog, isNew: boolean) => {
    // when saved, notify candidate card so it will refresh.
    const customEvent = new CustomEvent("candidate:tracker:updated", {
      detail: { log: savedLog, isNew: isNew },
    });
    document.dispatchEvent(customEvent);
  };

  useEffect(() => {
    if (context.user.cognitoUser?.Username) {
      BSGAPI.getCandidateMilestones(
        context.user.cognitoCredentials,
        context.user.cognitoUser,
        tracker.current.candidate.companyId as string,
        tracker.current.candidate.id as string
      ).then((response2: any) => {
        //console.log("response3: " + JSON.stringify(response2));
        if (response2.status == "ok") {
          tracker.current.milestones = response2.data.map((milestone: DLog) => {
            return {
              ...milestone,
              person: tracker.current.candidate.id,
              company: tracker.current.candidate.companyId,
            };
          });
          //setLoading(false);
          setKey(new Date().toString());
        } else {
        }
      });
    }
  }, [tracker.current.candidate]);

  useEffect(() => {
    // receive event from candidate card, when log is updated
    document.addEventListener("candidate:log:updated", function (e: any) {
      console.log("Received ..... Event: " + JSON.stringify(e.detail));

      tracker.current.milestones = tracker.current.milestones.map((obj) => {
        return obj != null &&
          e.detail.log != null &&
          obj.id != null &&
          obj.id == e.detail.log.id
          ? e.detail.log
          : obj;
      });
      //setMilestones(newObjects);
      //tracker.current.milestones = newObjects;
      setKey(new Date().toString());
    });
    // receive event from candidate card, when log is deleted
    document.addEventListener("candidate:log:deleted", function (e: any) {
      //console.log("Received ..... Event: " + JSON.stringify(e.detail));

      let newObjects = tracker.current.milestones.map((obj) => {
        return obj.id != null && obj.id == e.detail.log.id
          ? ({ ...obj, milestone_value: "", note: "" } as DLog)
          : obj;
      });
      //setMilestones(newObjects);
      tracker.current.milestones = newObjects;
      setKey(new Date().toString());
    });
    return () => {
      document.removeEventListener("candidate:log:updated", () => {});
      document.removeEventListener("candidate:log:deleted", () => {});
    };
  }, []);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, m: 0 }}
      alignContent="center"
      justifyContent="center"
      alignItems="center"
    >
      {tracker.current.milestones?.map((item: any, index: number) => {
        return (
          <div>
            <MilestoneItem
              item={item}
              onSave={onSave}
              key={`${tracker.current.candidate.id}-${tracker.current.candidate.companyId}-milestone-${key}}-${index}`}
            />
          </div>
        );
      })}
    </Box>
  );
}
