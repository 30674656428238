import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
//import { useTranslation } from "react-i18next";
import { UserContext, UserContextProvider } from "../../context/user-context";

import useScrollTrigger from "../../hooks/scroll-trigger";
import { Button, Container, Fade, Grid } from "@mui/material";
import ThemeCard from "../../components/card/theme-card";
import "../../custom.d.ts";
import "./style.scss";
import Banner from "../../components/banner/banner";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import "url-search-params-polyfill";
import Card from "@mui/material/Card";
import { API } from "aws-amplify";
import {
  DJob,
  DLinkedInCompositeProfile,
  DLinkedInEmailHandler,
  DLinkedInProfile,
  DWebContent,
} from "../../model";
import JobCard from "../../components/card/job-card";
import ContactForm from "../../components/form/contact-form";
import { ContextService } from "../../context/context-service";
import { useNavigate } from "react-router";
import useDynamicRef from "../../hooks/dynamic-ref";
import { useParams } from "react-router-dom";
import TrainingEnrollmentForm from "../../components/form/training-enrollment-form";
import { BSGAPI } from "../../auth/bsg-api";

const TrainingEnrollment = (props: any) => {
  //const { children, window } = props;
  //const { t, i18n } = useTranslation("content");

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const history = useNavigate();

  const [webContent, setWebContent] = useState({} as DWebContent);
  
  //const id = props.match.params.id;
  //const action = props.match.params.action;

  const {id, action } = useParams(); 

  //const query = new URLSearchParams(props.location.search);

  //let myRefs = useRef<HTMLDivElement[]>(null);

  const [programId, setProgramId] = useState(id);

  const myRefWe = useRef<HTMLDivElement>(null);

  const [getRef, setRef] = useDynamicRef();

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  let [refMap, setRefMap] = useState(
    new Map<string, React.RefObject<HTMLDivElement>>()
  );

  //let refMap = useRef<Map<string, React.RefObject<HTMLDivElement>>>(new Map());

  useEffect(() => {
    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };


    BSGAPI.getWebContent("training-enrollment").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      //document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
      document.title = `${webContent.site}:${webContent.title}`;
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <ThemeCard
            backgroundImg="https://my-bsg-asset.s3.amazonaws.com/image/theme_bsg_services.jpg"
            opacity={1.0}
            title=""
            description=""
            caption=""
            minHeight={500}
          >
            <Container maxWidth="md">
              <h1 className="center secondary-light">{ webContent.h1?.title}</h1>
              <h1>
                <br />
              </h1>
            </Container>
          </ThemeCard>
        </div>
      </Fade>

      <div className="primary-light">
        <Container maxWidth="md">
          <TrainingEnrollmentForm programId={programId} programName="Coding Camp" programLink="https://www.bsg.academy/coding-camp"/>
        </Container>
      </div>
    </div>
  );
};

export default TrainingEnrollment;
