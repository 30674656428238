import { useContext, useEffect, useRef, useState } from "react";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Button, Container, Fade, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material/";
//import "../custom";
import "./style.scss";

import Banner from "../components/banner/banner";
import VideoThemeCard from "../components/card/video-theme-card";

import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SpeedIcon from "@mui/icons-material/Speed";

import ContactForm from "../components/form/contact-form";
import { BSGAPI } from "../auth/bsg-api";
import { DProfile, DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import { UserContext } from "../context/user-context";
import { AdminAPI2 } from "../auth/admin-api2";
import { useNavigate } from "react-router";
import Loading from "../components/loading/loading";

const Onboarding = (props: any) => {

  const history = useNavigate();
  
  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const [contactCardDisabled, setContactCardDisabled] = useState(true);

  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(""); 

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );


  const [profile, setProfile] = useState<DProfile>({
    fname: "",
    lname: "",
    phone: "",
    email: "",
    wechat: "",
    linkedin: ""
  });

  const [profileResponse, setProfileResponse] = useState<any>({ status: "", messages: [], err: "" });


  const callAPI = () => {

    setLoading(true);
    AdminAPI2.adminOnboardUser(
      context.user.cognitoCredentials,
      profile, 
      role
    ).then((json) => {
      console.log(json);
      //setData(data);  

      if (json.status == "ok") {
        setProfileResponse({...profileResponse, 
          status: json.status,
          messages: json.messages, 
          err: json.err
        });
      } else {
        setProfileResponse({...profileResponse, 
          status: json.status,
          messages: json.messages, 
          err: json.err
        });
      }

      setLoading(false);
      /**
      if(json.status == 'ok') {
        console.log("OK ..."); 
        setLoading(false); 
        setContactCardDisabled(true); 

        setProfileResponse(json);
        setProfile({});
        
      } else {
        console.log("BAD ..." + json.status); 
      }
        **/
    });
  };
  
  useEffect(() => {

    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.username);

        setLoading(false); 
        forceUpdate();
      }
      });
      
    document.title = "BSG:iLab";

    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };

    BSGAPI.getWebContent("onboarding").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });

    window.scrollTo(0, 0);

    //myApp.user?.setLinkedInFirstName(myApp.user, "Julian");
  }, []);

  return (
    <div>

      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>Onboarding</h1>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{webContent.h2?.title}</h2>
              </Grid>
            </Grid>
          </Banner>
        </div>
      </Fade>


      <div className="center">

      <Container maxWidth="lg">

      {loading ? (
            <div>
              <Loading show={loading} />

            </div>
          ) : (
      <div className="form">
        {profileResponse.status! != "ok" ? (
          <Grid container spacing={3}>

<Grid item xs={12}>
                <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="academy-user" control={<Radio />} label={<>BSG Academy User</>} 
          checked={ role == 'academy-user'}
          onChange={(e:any)=>{
            setRole(e.target.value); 
        }} />

        <FormControlLabel value="community-user" control={<Radio />} label={<div>BSG Community User</div>}
          checked={ role == 'community-user'}
            onChange={(e:any)=>{
              setRole(e.target.value); 
        }} />

      </RadioGroup>
    </FormControl>
                  
                </Grid>
            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                color="warning"
                id="email"
                label="Email"
                fullWidth={true}
                value={profile.email}
                onChange={(e) => {
                  setProfile({ ...profile, email: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                color="secondary"
                id="fname"
                label="First Name"
                fullWidth={true}
                value={profile.fname}
                onChange={(e) => {
                  setProfile({ ...profile, fname: e.target.value });
                }}
              />
            </Grid>            
            <Grid item xs={12}>
              <TextField
                required
                variant="outlined"
                color="secondary"
                id="lname"
                label="Last Name"
                fullWidth={true}
                value={profile.lname}
                onChange={(e) => {
                  setProfile({ ...profile, lname: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                color="secondary"
                id="phone"
                label="Phone #"
                fullWidth={true}
                value={profile.phone}
                onChange={(e) => {
                  setProfile({ ...profile, phone: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                color="secondary"
                id="wechat"
                label="WeChat"
                fullWidth={true}
                value={profile.wechat}
                onChange={(e) => {
                  setProfile({ ...profile, wechat: e.target.value });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                color="secondary"
                id="linkedin"
                label="LinkedIn"
                fullWidth={true}
                value={profile.linkedin}
                onChange={(e) => {
                  setProfile({ ...profile, linkedin: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              * Your information will only be collected for this business
              communication purpose.
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={callAPI}
                disabled={
                  profile.fname!.length < 2 ||
                  profile.lname!.length < 2 ||
                  profile.email!.length < 2
                }
              >
                Submit
              </Button>
              <h3 className="warning-dark">{profileResponse.message} {profileResponse.err}</h3>


            </Grid>
          </Grid>
        ) : (
          <div>

          <Grid container spacing={3}>

<Grid item xs={12}>
          <h2>Thank you!</h2>
          <h3 className="warning-dark">{profileResponse.message} </h3>
          <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  history(`/admin/user-profile/${profile["email"]}`);
                }}
                
              >
                View/Edit User Profile
              </Button>
</Grid>
</Grid>
              </div>

        )}




<Grid container spacing={3}>

<Grid item xs={12}>

{profileResponse.messages.map((child: any) => {
                return (<h3><li>{child.task}: {child.status}: {child.message}: {child.err} </li></h3>);
          })}
</Grid>
</Grid>


      </div>
          )}

    </Container>
      </div>

    </div>
  );
};

export default Onboarding;
