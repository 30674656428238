import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/user-context";

import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { DUser } from "../../model";
import { BSGAuth } from "../../auth/bsg-auth";

import { NVL } from '@neo4j-nvl/base'
import { BasicNvlWrapper } from '@neo4j-nvl/react'

import type { HitTargets, Node, Relationship } from '@neo4j-nvl/base'
import { InteractiveNvlWrapper } from '@neo4j-nvl/react'
import type { MouseEventCallbacks } from '@neo4j-nvl/react'

import "./style.css";

const Neo4j = (props: any) => {

  const history = useNavigate();

  const { context } = useContext(UserContext);

  const nodes = [{ id: '1' }, { id: '2' }]
const relationships = [{ id: '12', from: '1', to: '2' }]


  const init = ()=> { 

    const nvl = new NVL(document.getElementById('neo4j-container') as HTMLElement, nodes, relationships)

  }

  const mouseEventCallbacks: MouseEventCallbacks = {
    onHover: (element: Node | Relationship, hitTargets: HitTargets, evt: MouseEvent) =>
      console.log('onHover', element, hitTargets, evt),
    onRelationshipRightClick: (rel: Relationship, hitTargets: HitTargets, evt: MouseEvent) =>
      console.log('onRelationshipRightClick', rel, hitTargets, evt),
    onNodeClick: (node: Node, hitTargets: HitTargets, evt: MouseEvent) =>
      console.log('onNodeClick', node, hitTargets, evt),
    onNodeRightClick: (node: Node, hitTargets: HitTargets, evt: MouseEvent) =>
      console.log('onNodeRightClick', node, hitTargets, evt),
    onNodeDoubleClick: (node: Node, hitTargets: HitTargets, evt: MouseEvent) =>
      console.log('onNodeDoubleClick', node, hitTargets, evt),
    onRelationshipClick: (rel: Relationship, hitTargets: HitTargets, evt: MouseEvent) =>
      console.log('onRelationshipClick', rel, hitTargets, evt),
    onRelationshipDoubleClick: (rel: Relationship, hitTargets: HitTargets, evt: MouseEvent) =>
      console.log('onRelationshipDoubleClick', rel, hitTargets, evt),
    onCanvasClick: (evt: MouseEvent) => console.log('onCanvasClick', evt),
    onCanvasDoubleClick: (evt: MouseEvent) => console.log('onCanvasDoubleClick', evt),
    onCanvasRightClick: (evt: MouseEvent) => console.log('onCanvasRightClick', evt),
    onDrag: (nodes: Node[]) => console.log('onDrag', nodes),
    //onPan: (evt: MouseEvent) => console.log('onPan', evt),
    onPan: true, 
    onZoom: (zoomLevel: number) => console.log('onZoom', zoomLevel)
  }

  useEffect(() => {

    BSGAuth.config();



    context.cognitoGetUser().then((user: DUser) => {
      if (user.cognitoUser?.Username) {

        init();

      } else {
        history("/sign-in");
      }
    });


  });

  return (
    <>
    <h1>Neo4j Test</h1>
      <div id="neo4j-container" className="neo4j-container"></div>

<hr></hr>

<Container maxWidth="lg">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >

<InteractiveNvlWrapper nodes={nodes} rels={relationships} mouseEventCallbacks={mouseEventCallbacks} style={{width: "auto", height: "auto", minWidth: 600, minHeight: 600, border: "1px solid green"}}/>

</Grid>

</Grid>
</Container>
<hr></hr>
    </>
  );
}

export default Neo4j;