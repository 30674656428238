import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { DCompany, DContent, DNode, DPerson } from "../../model";
import YouTubeCard from "./youtube-card";
import GoogleSlideCard from "./google-slide-card";
import ImageCard from "./image-card";
import MemberCard from "./member-card";
import CompanyCard from "./company-card";

const NodeCard = (props: PropsWithChildren<{ node: DNode, onClick: any, onClickText: string }>) => {
  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const node = props.node as DNode;

  if (node == null) return <h1>Empty/null</h1>;
  
  switch (node.type) {
    case "User":
      return (
        <MemberCard member={node.value as DPerson} 
        onClick={props.onClick} 
        onClickText={props.onClickText}></MemberCard>
      );
    case "Person":
      return (
        <MemberCard member={node.value as DPerson} 
        onClick={props.onClick} 
        onClickText={props.onClickText}></MemberCard>
      );
    case "Company":
      return (
<CompanyCard company={node.value as DCompany} 
        onClick={props.onClick} 
        onClickText={props.onClickText}></CompanyCard>
      );
    default:
      return <h1>Empty/null</h1>;
  }
};

export default NodeCard;
