import Amplify, { Auth } from "aws-amplify";

import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";

import "./style.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../context/user-context";
import { useNavigate } from "react-router-dom";
import Banner from "../components/banner/banner";
import { BSGAPI } from "../auth/bsg-api";
import { DFormValidation, DWebContent } from "../model";
import BPhoneField from "../components/form/phone-field";
import { ContextService } from "../context/context-service";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThemeCard from "../components/card/theme-card";

// You can get the current config object
const currentConfig = Auth.configure();

const Promo = (props: any) => {
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const history = useNavigate();

  let myRef = useRef<HTMLTextAreaElement>(null);

  const [webContent, setWebContent] = useState({} as DWebContent);

  useEffect(() => {
    BSGAPI.getWebContentByLanguage("we", "cn").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {webContent.theme && (
        <ThemeCard
          backgroundImg={webContent.theme.image}
          opacity={0.9}
          title={webContent.theme.title as string}
          description={webContent.theme.description as string}
          minHeight={webContent.theme.height}
          caption={webContent.theme.caption}
        >
          <Container maxWidth="md">
            <h1 className="center secondary-light">
              {webContent.theme.h1?.title}
            </h1>
            {webContent.theme.h1?.children?.map((child) => {
              return (
                <h2 className="center secondary-light">{child.content}</h2>
              );
            })}
            <h2 className="center secondary-light">
              {webContent.theme.h2?.title}
            </h2>
            {webContent.theme.h2?.children?.map((child) => {
              return (
                <h3 className="center secondary-light">{child.content}</h3>
              );
            })}
          </Container>
        </ThemeCard>
      )}

      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

      {webContent.faqs && (
        <div className="section-white">
          <Container maxWidth="md">
            <div>
              <h2>欢迎更多了解BSG</h2>
            </div>
            {webContent.faqs?.map((question, index) => (
              <Accordion key={`faq-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h2 className="secondary-light">{question.question}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<h3>${question.answer}</h3>`,
                    }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </div>
      )}
    </div>
  );
};

export default Promo;
