import { Button, Card, CircularProgress, Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ThemeCard from "../components/card/theme-card";
import SMSVerifyer from "../components/form/sms-verifier";
import { BSGAPI2 } from "../auth/bsg-api2";
import { DCalendarEvent, DCalendarEventSignup, DVerification } from "../model";
import { DWZUtil } from "../auth/dwz-util";

export default function EventSignup() {

  const { g } = useParams(); 

  const group =

  //searchParams.get("g") != null ? (searchParams.get("g") as string) : "";
  (g != null)? g as string:"";

  const [event, setEvent] = useState([] as DCalendarEvent[]);
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [message, setMessage] = useState("Hello, there!");

  const [loading, setLoading] = useState(false);
  const [showVerifier, setShowVerifier] = useState(false);
  const [signup, setSignup] = useState<DCalendarEventSignup>({ 
    email: '', 
    calendarID: '', 
    eventID: ''

  });

  const [verification, setVerification] = useState<DVerification>({
    phone: "",
    intent: "julianzhu.us:bsg-event-signup",
    code: "",
  });

  const getCalendarEvent = () => {
    BSGAPI2.listCalendarEvent(group).then((json) => {
      setEvent(json.data as DCalendarEvent[]);
      if(json.data.length > 0) {
        let defaultSignup = json.data[0] as DCalendarEvent; 
        setSignup({ ...signup, calendarID: defaultSignup.calendarID, eventID: defaultSignup.eventID });
      }
      setLoadingEvent(false);
      setMessage("I am glad you are here!"); 
    });
  }

  const submitSignup = (verification: DVerification) => {
    setLoading(true);
    BSGAPI2.signupCalendarEvent(verification, signup).then((json) => {
      if (json.status == "ok") {
        setIsComplete(true);
      setMessage("Success!"); 
      } else {
        setShowVerifier(true);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getCalendarEvent();
    //setLoadingEvent(false);
  }, []);


  return (
    <>
    <ThemeCard
    backgroundImg="https://dwz-data.s3.amazonaws.com/assets/images/boston_04.jpg"
    title=""
    description=""
    minHeight={1000}
    opacity={1}
  >
      <Container maxWidth="lg">
        <div className="section-white">
          <Grid
            container
            rowSpacing={8}
            columnSpacing={0}
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >



  <Grid item xs={12} sm={12} md={12} lg={12}>
        <div>
          <h1>{message }</h1>
        </div>
  </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">

          {loadingEvent ? (
            <>
            <CircularProgress color="secondary" sx={{ fontSize: 80 }} />

            <h3>Loading event ...</h3></>
          ) : (
            <></>
          )}
          {" "}
  </Grid>




  { isComplete? <Grid item xs={12} sm={12} md={12} lg={12} textAlign="left">Thank you! Your request has been submitted! Please check your email for calendar invitation. </Grid>

  : <>
  <Grid item xs={12} sm={12} md={12} lg={12} textAlign="left">
  <FormControl>
  <FormLabel id="demo-radio-buttons-group-label"><h3>Select an event to sign up</h3></FormLabel>
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue=""
      name="radio-buttons-group"
    >

            {event?.map((obj, index) => {
              return (
                <FormControlLabel key={`radio-${index}`}value={index} control={<Radio />} label={obj.name} 
                  onClick={()=>{
                    setSignup({ ...signup, calendarID: obj.calendarID, eventID: obj.eventID });
                }}/>
              );
            })}
    </RadioGroup>
  </FormControl>
  </Grid>
  <Grid item xs={12} sm={12} md={12} lg={12} textAlign="left">
  <TextField
    required
    variant="outlined"
    color="secondary"
    id="email"
    size="medium"
    label="Email"
    disabled={loading}
    value={signup.email}
    onChange={(e) => {
      setSignup({ ...signup, email: e.target.value });
    }}
    fullWidth
  />
  </Grid>

  <Grid item xs={12} sm={12} md={12} lg={12} textAlign="left">
  <Button
    variant="contained"
    color="secondary"
    disabled={loading || signup.calendarID == '' || signup.eventID == '' || signup.email == ''
      || !DWZUtil.validate(
        DWZUtil.RegExp_EMAIL,
        "email",
        signup.email,
        null
      ).isValid
    }
    onClick={() => {
      submitSignup(verification);
    }}
  >
    Submit
  </Button>
  </Grid>
  </>
  }


  <Grid item xs={12} sm={12} md={12} lg={12} textAlign="left">
  <div
    style={{ width: "100%", textAlign: "center", alignItems: "center" }}
  >
    {loading ? (
      <>
        <CircularProgress color="secondary" sx={{ fontSize: 80 }} />
        <p>Submitting your request, please wait ....</p>
      </>
    ) : (
      ""
    )}
  </div>

  </Grid>







  {showVerifier ? (
    <SMSVerifyer
      verification={verification}
      onCancel={() => {
        setShowVerifier(false);
      }}
      onConfirm={(phone: string, code: string) => {
        console.log(
          "User verified and let's completing ... process: Code is: " +
            code +
            " for phone number: " +
            phone
        );
        setVerification({ ...verification, phone: phone });
        setVerification({ ...verification, code: code });
        var newVerification = {
          phone: phone,
          code: code,
          intent: verification.intent,
        };
        submitSignup(newVerification);
        setShowVerifier(false);
      }}
    />
  ) : (
    <></>
  )}


          </Grid>
        </div>
      </Container>
  </ThemeCard>
</>
  );
}
