export class DWZUtil {
  static SITE_NAME = "JulianZhu.us";

  static UTC_LONDON: number = 0;
  static UTC_BOSTON: number = -5;
  static UTC_TOKYO: number = +9;
  static UTC_LA: number = -8;
  static UTC_NEWDELHI: number = 5.5;
  static UTC_SINGAPORE: number = +8;
  static UTC_BERLIN: number = +1;
  static UTC_BEIJING: number = +8;

  static RegExp_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

static RegExp_PHONE =
  /(^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$){1}/;

static RegExp_PHONE_ALLOWED_INPUT = /[\d-. \(\)]/;
//static RegExp_PASSWORD = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/; // require at least 1 number and 1 special characters,
static RegExp_PASSWORD = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;

static RegExp_RECOVERY_CODE = /^[a-zA-Z0-9!@#$%^&*]{4,10}$/;


//check to see if it is daylight saving time
  static isDST(d: Date) {
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();    
}

  static getDateByUTCOffset(offset: number) {
    var now = new Date();
    if(this.isDST(now)) offset ++;
    var utc = now.getTimezoneOffset() * 60 * 1000 + now.getTime();
    var date = new Date(offset * 60 * 60 * 1000 + utc);

    return date;
  }

    // a generic data format validation method
    static validate(
      regEx: RegExp,
      name: string,
      value: string | undefined | null,
      help: string | undefined | null
    ) {
      if (value == null || value == undefined)
        return { isValid: true, err: `${name} cannot be empty` };
      let isValid = regEx.test(value.toLowerCase());
      let err = isValid ? "" : help ? help : `please provide a valid ${name}`;
      return { isValid: isValid, err: err };
    }
}
