import { useEffect, useRef, useState } from "react";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Accordion, AccordionDetails, AccordionSummary, Container, Fade, Grid } from "@mui/material/";
import "../custom.d.ts";
import "./style.scss";

import Banner from "../components/banner/banner";
import VideoThemeCard from "../components/card/video-theme-card";

import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SpeedIcon from "@mui/icons-material/Speed";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; 

import ContactForm from "../components/form/contact-form";
import { BSGAPI } from "../auth/bsg-api";
import { ContextService } from "../context/context-service";
import { DWebContent } from "../model";

const GetStarted = (props: any) => {

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  const [webContent, setWebContent] = useState({} as DWebContent);

  useEffect(() => {
    document.title = "BSG:Get Started";

    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };
    BSGAPI.getWebContent("get-started").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });

    window.scrollTo(0, 0);

    //myApp.user?.setLinkedInFirstName(myApp.user, "Julian");
  }, []);

  return (
    <div>

      
      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>{webContent.h1?.title}</h1>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{webContent.h1?.description}</h2>
                <h3>{webContent.h1?.content}</h3>
              </Grid>
            </Grid>
          </Banner>
        </div>
      </Fade>


      <div>
{webContent.content && (
        <div className="section-white">
          <Container maxWidth="lg">
            <div>
              <h2>{webContent.h2?.title}</h2>
              <h3>{webContent.h2?.description}</h3>
            </div>
            {webContent.content?.map((content, index) => (
              <Accordion key={`faq-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h2 className="secondary-light">{content.title}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<h3>${content.content}</h3>`,
                    }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </div>
      )}

</div>

    </div>
  );
};

export default GetStarted;
