import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Box, Container, Grid } from "@mui/material";
import { DDay } from "../../model";
import { BSGCalendar } from "./bsg-calendar";

const CalendarDay = (props: any) => {
  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const day = props.day as DDay;

  const today = new Date();

  return (
    <div className="day-card">
      <Box>
        <h5 className="primary-dark">
          {BSGCalendar.MONTH[day.date.getMonth()]}
        </h5>
        <h5 className="center secondary-light"> {day.date.getDate()}</h5>
      </Box>
    </div>
  );
};

export default CalendarDay;
