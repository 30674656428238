import {
  Box,
  Button,
  Container,
  Fab,
  Fade,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
  Tooltip,
  useScrollTrigger,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { Buffer } from 'buffer';
import "./style.scss";
import { Link } from "react-router-dom";
import { DInquiry, DNameValue } from "../../model";
import { API } from "aws-amplify";
import { useState } from "react";
import { flexbox } from "@mui/system";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BSGAPI } from "../../auth/bsg-api";

export interface ITextField {
  label?: string;
  tip?: string;
  editMode?: boolean;
  canEdit?: boolean; 
  name?: string;
  value?: string | number;
  multiline?: boolean;
  onChange?: any;
  onCommit?: any;
}

const BMediaPicker = ({
  label,
  tip,
  editMode,
  canEdit = true, 
  name,
  value,
  multiline,
  onChange,
  onCommit,
}: ITextField) => {
  const [myEditMode, setMyEditMode] = useState(editMode);

  const [uploadError, setUploadError] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");

  const [data, setData] = useState(value);

  function compressImage(imageType: any, imageSrc: any, newX: number) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = 'data:image/' + imageType + ';base64,' + imageSrc;
      img.onload = () => {
        var elem = document.createElement('canvas');
        elem.width = newX;
        const newY = img.height * newX / img.width; // ratio
        elem.height = newY; 
        let ctx = elem.getContext('2d');
        ctx?.drawImage(img, 0, 0, newX, newY);
        const compressedData = ctx?.canvas.toDataURL();
        console.log("res: " + compressedData); 
        res(compressedData as string);
      }
      img.onerror = error => rej(error);
    })
  }

  function onUploadFileChange(e: any) {
    
    let myFile: File = e.target.files[0];
    if(myFile == null) return;
    console.log("file name ==> " + myFile.name);
    let fileParts = myFile.name.split(".");
    let fileName = fileParts[0];
    let fileType = fileParts[1];
    let myReader = new FileReader();
    myReader.onload = ((file: File): any => {
      return (event: any) => {
        console.log("onLoaded: completed");
        var binaryString = event.target.result;
        var base64 = Buffer.from(binaryString, "binary").toString("base64");
        if (base64.length > BSGAPI.MAX_UPLOAD_FILE_SIZE) {
          setUploadError(true);
          setUploadMessage("File is too big ...");
        } else if (
          fileType.toLowerCase() != "jpg" &&
          fileType.toLowerCase() != "jpeg" &&
          fileType.toLowerCase() != "png" &&   
          fileType.toLowerCase() != "gif"
        ) {
          setUploadError(true);
          setUploadMessage("Only PDF/Doc document type is accepted");
        } else {
          setUploadError(false);
          setUploadMessage(
            "resume is ready to be submitted with your application"
          );
          //setData('data:image/' + fileType + ';base64,' + base64);
          compressImage(fileType, base64, 100).then((compressed: any) => {
            //this.imageTest = 'data:image/gif;base64,' + (compressed as string);
            //setData('data:image/' + fileType + ';base64,' + compressed);
            setData(compressed); 
            onChange(compressed); 
            console.log("compressed: " + compressed);
          });
        }
      };
    })(myFile);
    myReader.readAsArrayBuffer(myFile);
    //myReader.readAsBinaryString(myFile);
  }

  return (
    <div style={{ width: "100%" }}>
      
      <Box sx={{ width: "100%" }}>
        <h3>
          {label}
          {tip && (
            <Tooltip title={tip} arrow>
              <HelpOutlineIcon style={{ fontSize: 20, padding: 2 }} />
            </Tooltip>
          )}
        </h3>
      </Box>



      <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >


<Box
            flexGrow={1}
            sx={{ border: "1px solid silver", p: 1, borderRadius: "15px" }}
          >
            { data ? <img src={data as string} /> : <h3 className="left warning-dark">(not set)</h3> }
          </Box>

          <Box>
            <IconButton
              color="secondary"
              aria-label="upload"
              component="label"
              disabled={!canEdit}
            >
              <EditIcon />{" "}
              <input
                style={{ display: "none" }}
                type="file"
                onChange={(e) => {
                if (e.target.files) {
                  //console.log("file name: " + e.target.files[0].name);
                  onUploadFileChange(e);
                  //setInquiry({ ...inquiry, resume: e.target.files[0] });
                }
              }}
            />
            </IconButton>
          </Box>
          <div 
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            {uploadError ? (
                      <h3 className="warning-dark">
                        <b>Failed:</b> {uploadMessage}
                      </h3>
                    ) : (
                      uploadMessage != "" && (
                        <h4 className="success-dark">
                          <b>Looks good: </b>
                        </h4>
                      )
                    )}
          </div>

        </div>
    </div>
  );
};

export default BMediaPicker;
