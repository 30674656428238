import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext, UserContextProvider } from "../context/user-context";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Button, Container, Fade, Grid, TextField } from "@mui/material";
import ThemeCard from "../components/card/theme-card";
import "../custom.d.ts";
import "./style.scss";
import Banner from "../components/banner/banner";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import EmailIcon from "@mui/icons-material/Email";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import Card from "@mui/material/Card";
import { API } from "aws-amplify";
import {
  DFormValidation,
  DJob,
  DLinkedInCompositeProfile,
  DLinkedInEmailHandler,
  DLinkedInProfile,
} from "../model";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BSGAPI } from "../auth/bsg-api";
import { ContextService } from "../context/context-service";


const ForgotPassword = (props: any) => {
  //const { children, window } = props;
  const { t, i18n } = useTranslation("content");

  const { step } = useParams();

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const history = useNavigate();

  //  const myStep = props.match.params.step;

  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false); // track if the form is touched by user.
  const [emailValidation, setEmailValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);

  const [code, setCode] = useState("");
  const [codeTouched, setCodeTouched] = useState(false); // track if the form is touched by user.
  const [codeValidation, setCodeValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [passwordTouched, setPasswordTouched] = useState(false); // track if the form is touched by user.
  const [passwordValidation, setPasswordValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);

  const [passwordAgainValidation, setPasswordAgainValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);



  const [currentStep, setCurrentStep] = useState(
    //props.match.params.step ? props.match.params.step : 0
    step? step : 0
  );


  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  const forgotPassword = () => {
    //console.log("old/new: " + oldPassword + ":" + newPassword);
    context
      .cognitoForgotPassword(email)
      //.cognitoForgotPassword("XYZ123abc", "123abcXYZ")
      .then((data) => {
        //console.log("data: " + data);
        //if (data == "SUCCESS") {
        setCurrentStep(1);
        //}
      })
      .catch((err) => {
        //console.log("err: " + err);
        setCurrentStep(0);
      });
  };

  const confirmForgotPassword = () => {
    //console.log("old/new: " + oldPassword + ":" + newPassword);
    context
      .cognitoConfirmForgotPassword(email, code, password)
      //.cognitoForgotPassword("XYZ123abc", "123abcXYZ")
      .then((data) => {
        //console.log("data: " + data);
        //if (data == "SUCCESS") {
        setCurrentStep(2);
        //}
      })
      .catch((err) => {
        //console.log("err: " + err);
        setCurrentStep(0);
      });
  };

  useEffect(() => {
    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };

    document.title = `${ContextService.SITE_NAME}:Forgot Password`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>Forgot Password?</h1>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>No problem - let's recover and reset your password. </h2>
              </Grid>
            </Grid>
          </Banner>
        </div>
      </Fade>

      <div className="section-white">
        <Container maxWidth="md">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignContent="flex-start"
          >
            {currentStep == 0 && (
              <div>
                <h2>
                  Please provide your email and we'll send a recovery code.
                </h2>

                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="flex-start"
                >
                  <TextField
                    required
                    id="outlined-required"
                    variant="outlined"
                    color="secondary"
                    label="Email"
                    defaultValue={email}
                    value={email}
                    error={!emailValidation.isValid}
                    helperText={emailValidation.err}
                    onChange={(e) => {
                      setEmailTouched(true);
                      setEmail(e.target.value);
                      setEmailValidation(
                        BSGAPI.validate(
                          BSGAPI.RegExp_EMAIL,
                          "email",
                          e.target.value,
                          null
                        )
                      );
                    }}
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <h1></h1>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => forgotPassword()}
                    disabled={!emailTouched || !emailValidation.isValid}
                  >
                    Request Recovery Code
                  </Button>
                </Grid>
              </div>
            )}

            {currentStep == 1 && (
              <div>
                <div>
                  <Grid
                    container
                    spacing={4}
                    direction="row"
                    justifyContent="flex-start"
                    alignContent="flex-start"
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <h2>
                        Recovery code has been requested and sent to your email.
                      </h2>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <EmailIcon color="secondary" />
                      <TextField
                        required
                        id="outlined-required"
                        variant="outlined"
                        color="secondary"
                        label="Email"
                        defaultValue={email}
                        value={email}
                        error={!emailValidation.isValid}
                        helperText={emailValidation.err}
                        onChange={(e) => {
                          setEmailTouched(true);
                          setEmail(e.target.value);
                          setEmailValidation(
                            BSGAPI.validate(
                              BSGAPI.RegExp_EMAIL,
                              "email",
                              e.target.value,
                              null
                            )
                          );
                        }}
                      />
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <VpnKeyIcon color="secondary" />{" "}
                      <TextField
                        required
                        id="outlined-required"
                        variant="outlined"
                        color="secondary"
                        label="Code"
                        defaultValue={code}
                        value={code}
                        error={!codeValidation.isValid}
                        helperText={codeValidation.err}
                        onChange={(e) => {
                          setCodeTouched(true);
                          setCode(e.target.value);
                          setCodeValidation(
                            BSGAPI.validate(
                              BSGAPI.RegExp_RECOVERY_CODE,
                              "code",
                              e.target.value,
                              "Recovery code was sent to your email"
                            )
                          );
                        }}
                      />
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <VpnKeyIcon color="secondary" />{" "}
                      <TextField
                        required
                        type="password"
                        id="outlined-required"
                        variant="outlined"
                        color="secondary"
                        label="New password"
                        error={!passwordValidation.isValid}
                        helperText={passwordValidation.err}
                        defaultValue={password}
                        onChange={(e) => {
                          //setTouched(true);
                          setPasswordTouched(true);
                          setPassword(e.target.value);
                          setPasswordValidation(
                            BSGAPI.validate(
                              BSGAPI.RegExp_PASSWORD,
                              "password",
                              e.target.value,
                              "Password minimum length: 6"
                            )
                          );
                          setPasswordAgainValidation({
                            isValid: passwordAgain == e.target.value,
                            err:
                              passwordAgain == e.target.value
                                ? ""
                                : "Password does match",
                          });
                        }}
                      />
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <VpnKeyIcon color="secondary" />{" "}
                      <TextField
                        required
                        type="password"
                        id="outlined-required"
                        variant="outlined"
                        color="secondary"
                        label="Password (again)"
                        error={!passwordAgainValidation.isValid}
                        helperText={passwordAgainValidation.err}
                        defaultValue={password}
                        onChange={(e) => {
                          //setTouched(true);
                          //setPasswordTouched(true);
                          //setPassword(e.target.value);

                          setPasswordAgain(e.target.value);
                          setPasswordAgainValidation({
                            isValid: password == e.target.value,
                            err:
                              password == e.target.value
                                ? ""
                                : "Password does not match",
                          });
                        }}
                      />
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <h1></h1>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => confirmForgotPassword()}
                        disabled={
                          !emailTouched ||
                          !emailValidation.isValid ||
                          !passwordTouched ||
                          !passwordValidation.isValid ||
                          !passwordAgainValidation.isValid ||
                          !codeTouched ||
                          !codeValidation.isValid
                        }
                      >
                        Reset Password
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}

            {currentStep == 2 && <div>You are all set!</div>}

            <Grid item xs={12}>
              <h4>
                <Link to="/sign-in"> Sign In? </Link> |
                <Link to="/sign-up"> Sign Up? </Link> |
                <Link to="/forgot-password/1"> I have code </Link> |
                <Link to="/sign-up/2"> Confirm my email </Link>
              </h4>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default ForgotPassword;
