import { ContextService } from "../context/context-service";
import {
  DCognitoCredentials,
  DCognitoUser,
  DLog,
  DLogItem,
  DNameValue,
  DOpportunity,
  DRelationship,
} from "../model";


export class AdminAPI2 {
 
  constructor() {}

  static config() {
    //console.log("This is config of BSG API class");
  }


  static myTest(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        }),
      };
      fetch("/api2/my-test", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static adminGetProfiles(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        }),
      };
      fetch("/api2/admin-get-profiles", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static adminOnboardUser(
    credentials: DCognitoCredentials, 
    profile: any, 
    role: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          profile: profile, 
          role: role
        }),
      };
      fetch("/api2/admin-onboard-user", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static adminSetupFolder(
    credentials: DCognitoCredentials, 
    email: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          email: email
        }),
      };
      fetch("/api2/admin-setup-folder", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static adminGetProfile(
    credentials: DCognitoCredentials, 
    email: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          email: email
        }),
      };
      fetch("/api2/admin-get-profile", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 
  
  static adminUpdateProfile(
    credentials: DCognitoCredentials, 
    profile: any
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          profile: profile
        }),
      };
      fetch("/api2/admin-update-profile", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static adminGetPerson(
    credentials: DCognitoCredentials, 
    email: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          email: email
        }),
      };
      fetch("/api2/admin-get-person", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static adminUpdatePerson(
    credentials: DCognitoCredentials, 
    profile: any
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          profile: profile
        }),
      };
      fetch("/api2/admin-update-person", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static adminGetCompany(
    credentials: DCognitoCredentials, 
    id: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          id: id
        }),
      };
      fetch("/api2/admin-get-company", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static adminUpdateCompany(
    credentials: DCognitoCredentials, 
    company: any
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          company: company
        }),
      };
      fetch("/api2/admin-update-company", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }


  static adminGetBusiness(
    credentials: DCognitoCredentials, 
    id: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          id: id
        }),
      };
      fetch("/api2/admin-get-business", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static adminUpdateBusiness(
    credentials: DCognitoCredentials, 
    business: any
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          business: business
        }),
      };
      fetch("/api2/admin-update-business", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static adminGetNetwork(
    credentials: DCognitoCredentials, 
    id: string | null, keyword: string | null
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          id: id, 
          keyword: keyword
        }),
      };
      fetch("/api2/admin-get-network", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static adminDeleteRelationship(
    credentials: DCognitoCredentials, 
    relationship: DRelationship
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          relationship: relationship
        }),
      };
      fetch("/api2/admin-delete-relationship", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static adminUpdateRelationship(
    credentials: DCognitoCredentials, 
    relationship: DRelationship
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          relationship: relationship
        }),
      };
      fetch("/api2/admin-update-relationship", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 


}
