import {
  CircularProgress
} from "@mui/material";

import "./style.scss";

export default function Loading(props: any) {
  return props.show ? (
    <CircularProgress color="secondary" sx={{ fontSize: 280 }} />
  ) : (
    <></>
  );
}
