import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Box, Container, Grid } from "@mui/material";
import { DOpportunity } from "../../model";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { BSGAPI } from "../../auth/bsg-api";

const OpportunityCard = (props: any) => {
  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const opportunity = props.opportunity as DOpportunity;

  return opportunity ? (
    <Card className="opportunity-card">
      <Box>
        <h1 className="center warning-dark">
          <AccountCircleIcon color="info" style={{ fontSize: 64 }} />
        </h1>
      </Box>
      <Box>
        <h3 className="center">{BSGAPI.formatDateString(opportunity.date)}</h3>
      </Box>
      <Box flexGrow={1}>
        <h2 className="center info-dark">
          {opportunity.person}
          {opportunity.linkedIn ? (
            <a href="{opportunity.linkedIn}" target="_blank">
              <LinkedInIcon color="warning" />
            </a>
          ) : (
            ""
          )}
        </h2>

        <h3 className="">Type: {opportunity.type}</h3>

        <div
          dangerouslySetInnerHTML={{
            __html: `<h3>${opportunity.note}</h3>`,
          }}
        ></div>
      </Box>
      <Box alignItems="flex-end">
        <CardActions>
          <div className="action-button">
            {props.route ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                startIcon={<ArrowForwardIosOutlinedIcon />}
                onClick={() => {
                  routeTo(props.route as string);
                }}
              >
                {props.routeText}
              </Button>
            ) : (
              ""
            )}

            {props.onClick ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => {
                  props.onClick();
                }}
              >
                {props.onClickText}
              </Button>
            ) : (
              ""
            )}

            {props.linkUrl ? (
              <a href={props.linkUrl} target="_blank">
                {props.linkText}
              </a>
            ) : (
              ""
            )}
          </div>
        </CardActions>
      </Box>
    </Card>
  ) : (
    <Card className="opportunity-card">
      <Box>
        <h1 className="center warning-dark">
          <ConnectWithoutContactIcon color="warning" style={{ fontSize: 64 }} />
        </h1>
      </Box>
      <Box>
        <h2 className="center warning-dark">Refer a new friend</h2>
      </Box>
      <Box>
        <h3 className="center">
          Refer a friend today and earn referral commission later.
        </h3>
        {props.onClick ? (
          <Button
            variant="contained"
            size="medium"
            color="warning"
            onClick={() => {
              props.onClick();
            }}
          >
            {props.onClickText}
          </Button>
        ) : (
          ""
        )}
      </Box>
      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      ></div>
    </Card>
  );
};

export default OpportunityCard;
