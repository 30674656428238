import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Button, Container, Fade, Grid, TextField } from "@mui/material";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { DNameValue, DProfile, DUser, DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import IconCard from "../components/card/icon-card";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import NameValueCard from "../components/card/name-value-card";
import BTextField from "../components/form/text-field";
import BSelectList from "../components/form/select-list";
import { BSGAPI } from "../auth/bsg-api";
import { BSGAPI2 } from "../auth/bsg-api2";
import BFormInput from "../components/form/form-input";
import Loading from "../components/loading/loading";
import LocationCityIcon from '@mui/icons-material/LocationCity';

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import HubIcon from '@mui/icons-material/Hub';
import { PublicAPI } from "../auth/public-api";

const MyProfile = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const [webContent, setWebContent] = useState({} as DWebContent);
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [ui, setUi] = useState([] as string[]); 

  //const [gdrive, setGdrive] = useState("");
  //let [profile, setProfile] = useState({} as DProfile);
  const [profile, setProfile] = useState({} as any);

  let [attributes, setAttributes] = useState([] as DNameValue[]);

  let [contactCardDisabled, setContactCardDisabled] = useState(true);
  let [payCardDisabled, setPayCardDisabled] = useState(true);
  let [professionCardDisabled, setProfessionCardDisabled] = useState(true);
  let [locationCardDisabled, setLocationCardDisabled] = useState(true);


  let [loading, setLoading] = useState(true);
  const [loadingContact, setLoadingContact] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingProfession, setLoadingProfession] = useState(false);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");


  const [loadingFolderSetup, setLoadingFolderSetup] = useState(false);
  const [showErrorFolderSetup, setShowErrorFolderSetup] = useState(false);
  const [errorFolderSetup, setErrorFolderSetup] = useState("");
  const [folderSetupButtonDisabled, setFolderSetupButtonDisabled] = useState(false); 


  const [states, setStates] = useState([]); 

  const updateProfile = () => {

    //setLoading(true);
    BSGAPI2.updateProfile(
      context.user.cognitoCredentials,
      profile
    ).then((json) => {
      console.log(json);
      //setData(data);  
      if(json.status == 'ok') {
        console.log("OK ..."); 
        //setObjects(json.data);  // for array type testing
        //setData(json.data); // for json type testing
        setLoading(false); 
        setLoadingContact(false);
        setLoadingLocation(false);
        setLoadingPayment(false);
        setLoadingProfession(false);
        setContactCardDisabled(true); 
      } else {
        console.log("BAD ..." + json.status); 
        setError(json.status);
        setShowError(true); 
      }
    });
  };

  const setupFolder = () => {

    setFolderSetupButtonDisabled(true);
    setLoadingFolderSetup(true);

    BSGAPI2.setupFolder(
      context.user.cognitoCredentials
    ).then((json) => {
      console.log(json);
      //setData(data);  
      if(json.status == 'ok') {
        console.log("OK ..."); 
        //setObjects(json.data);  // for array type testing
        //setData(json.data); // for json type testing
        setLoadingFolderSetup(false); 
        setProfile({...profile, gdrive: json.data});

        setContactCardDisabled(true); 
      } else {
        console.log("BAD ..." + json.status); 
        setErrorFolderSetup(json.status);
        setShowErrorFolderSetup(true); 
        setFolderSetupButtonDisabled(false);
      }
    });
  };

  useEffect(() => {
    document.title = "BSG Partner:My Profile";

    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        // for testing:
        //getMyProfile();

        // just for testing:
        //getMyAttributes();

        //setGdrive(context.user.person!.gdrive as string);

        BSGAPI2.getProfile(context.user.cognitoCredentials)
        .then((json) => {
          if(json.status == "ok") {
            console.log("JSON: " + JSON.stringify(json)); 

            if(json.data != null) 
              setProfile(json.data as DProfile);
  
            setLoading(false); 
/** 
          } else if (json.status == "token-expired") {
            setTimeout(() => {
              history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            }, 3000);
**/

            } 
          else {
            console.log("Erro: " + JSON.stringify(json)); 
          
          
          }
        }); 


        if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
          setUi(context.user.ui as string[]); 
        } else {
          setUi([]);
        }


        forceUpdate();
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/profile`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });

    BSGAPI.getWebContent("profile").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });

    PublicAPI.get("states").then((json) => {
      console.log("States: " + JSON.stringify(json) ); 
      setStates(json.states); 
    }).catch((e: any) => {
      console.log("States Error: " + e);
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

    {loading ? (
            <div>
              <Loading show={loading} />
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignContent="center"
              >
                {showError && (
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                      setShowError(false);
                    }}
                    severity="error"
                  >
                    {error}
                  </MuiAlert>
                )}
              </Grid>
            </div>
          ) : (


            <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid
                container
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            justifyContent="center"
            alignContent="center"
          >
            <NameValueCard
              key="info-contact"
              icon={
                <PermPhoneMsgIcon color="info" style={{ fontSize: 64 }} />
              }
              title="How We Contact You"
              description="If you need to update email, please contact Admin"
              onClick={() => {
                updateProfile();
                setContactCardDisabled(true);
                setLoadingContact(true);
              }}
              onClickText="Save"
              onClickDisabled={contactCardDisabled}
            >
              <h1></h1>
               {loadingContact ? (
              <Loading show={loadingContact} />
               ): 
               <>
              <BFormInput
                label="Mobile/SMS"
                editMode={false}
                name="mobile"
                value={profile["mobile"]}
                onChange={(value: any) => {
                  setProfile({...profile, mobile: value});
                  setContactCardDisabled(false);
                }}
              ></BFormInput>
              <BFormInput
                label="WeChat"
                editMode={false}
                name="wechat"
                value={profile["wechat"]}
                onChange={(value: any) => {
                  setProfile({...profile, wechat: value});
                  setContactCardDisabled(false);
                }}
              ></BFormInput>

              </>}
            </NameValueCard>
          </Grid>


          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            justifyContent="center"
            alignContent="center"
          >
            <NameValueCard
              key="info-contact"
              icon={
                <LocationCityIcon color="info" style={{ fontSize: 64 }} />
              }
              title="My City"
              description="Help community members connect with you locally"
              onClick={() => {
                updateProfile();
                setLocationCardDisabled(true);
                setLoadingLocation(true);
              }}
              onClickText="Save"
              onClickDisabled={locationCardDisabled}
            >
              <h1></h1>
              {loadingLocation ? (
              <Loading show={loadingLocation} />
               ): 
               <>
              <BFormInput
                label="city"
                editMode={false}
                name="city"
                value={profile["city"]}
                onChange={(value: any) => {
                  setProfile({...profile, city: value});
                  setLocationCardDisabled(false);
                }}
              ></BFormInput>
              <BSelectList
                label="State"
                editMode={false}
                name="state"
                value={profile["state"]}
                keyValueList={states}
                onChange={(value: any) => {
                  //setProfile({...profile, state: e.target.value}); 
                  setProfile({...profile, state: value}); 
                  setLocationCardDisabled(false);
                
                }}
              ></BSelectList>
              </>}
            </NameValueCard>
          </Grid>


          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            justifyContent="center"
            alignContent="center"
          >
            <NameValueCard
              key="info-profession"
              icon={
                <HubIcon color="info" style={{ fontSize: 64 }} />
              }
              title="Professional Network"
              description="Link to your LinkedIn profile and resume"
              onClick={() => {
                updateProfile();
                setProfessionCardDisabled(true);
                setLoadingProfession(true);
              }}
              onClickText="Save"
              onClickDisabled={professionCardDisabled}
            >
              <h1></h1>
              {loadingProfession ? (
              <Loading show={loadingProfession} />
               ): 
               <>
              <BFormInput
                label="LinkedIn"
                editMode={false}
                name="linkedin"
                value={profile["linkedin"]}
                onChange={(value: any) => {
                  setProfile({...profile, linkedin: value});
                  setProfessionCardDisabled(false);
                }}
              ></BFormInput>
              <BFormInput
                label="Resume"
                editMode={false}
                name="resume"
                value={profile["resume"]}
                onChange={(value: any) => {
                  setProfile({...profile, resume: value});
                  setProfessionCardDisabled(false);
                }}
              ></BFormInput>
              </>}
            </NameValueCard>
          </Grid>



          { ui.includes("item_payment")
          && 
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            justifyContent="center"
            alignContent="center"
          >
            <NameValueCard
              key="info-payment"
              icon={
                <MonetizationOnIcon color="info" style={{ fontSize: 64 }} />
              }
              title="How We Pay You"
              description=""
              onClick={() => {
                
                updateProfile();
                setContactCardDisabled(true);
                setLoadingPayment(true);

              }}
              onClickText="Save"
              onClickDisabled={payCardDisabled}
            >
              <h1></h1>
              {loadingPayment ? (
              <Loading show={loadingPayment} />
               ): 
               <>
              <BSelectList
                label="Payment Method"
                editMode={false}
                name="pay_method"
                value={profile["pay_method"]}
                list={["Venmo", "Paypal", "Check", "Zelle"]}
                onChange={(value: any) => {
                  //setAttribute("pay_method", e.target.value);
                  setPayCardDisabled(false);
                  setProfile({...profile, pay_method: value}); 
                }}
              ></BSelectList>
              <BFormInput
                label="Payment Method Info"
                tip="Provide account info for this payment method. For check, please provide mailing address. "
                editMode={false}
                name="pay_method_detail"
                value={profile["pay_method_detail"]}
                onChange={(value: any) => {
                  //setAttribute("pay_method_detail", e.target.value);
                  setPayCardDisabled(false);
                  setProfile({...profile, pay_method_detail: value}); 
                }}
              ></BFormInput>
              </>}
            </NameValueCard>
          </Grid>
        }

        { ui.includes("item_gdrive")
          && 
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            justifyContent="center"
            alignContent="center"
          >
            
          
            { profile["gdrive"] !== null && profile["gdrive"] !== '' && profile["gdrive"] !== undefined? 
            <IconCard
              key="info-gdrive"
              icon={
                <AccountBalanceIcon color="info" style={{ fontSize: 64 }} />
              }
              title="G-Folder"
              description="Google Drive Folder for storing, collaborating"
              onClick={() => {
                window.open(
                  `https://drive.google.com/drive/u/1/folders/${profile["gdrive"]}`,
                  "_blank"
                );
              }}
              onClickText="Open G-Drive"
            ></IconCard> : 
            <IconCard
              key="info-gdrive"
              color="warning"
              icon={
                <AccountBalanceIcon color="info" style={{ fontSize: 64 }} />
              }
              title="G-Folder"
              description="Google Drive Folder for storing, collaborating on documents including training materials, 1099 Tax documents, etc. if you do business with BSG. "
              onClick={() => {
                // call method to set up gdrive
                setupFolder();
              }}
              onClickText="Set Up Now"
              onClickDisabled={folderSetupButtonDisabled}
            >
              { loadingFolderSetup? 
                  <div>
                  <Loading show={loadingFolderSetup} />
                  
                  <h3>
                  It will take a few seconds while we are setting up folder. 
                  Please be patient and wait ... 
                  </h3>
                  </div>
          : 
          <>{showErrorFolderSetup && (
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={() => {
                setShowErrorFolderSetup(false);
              }}
              severity="error"
            >
              {errorFolderSetup}
            </MuiAlert>
          )}</>
                  }
            </IconCard>
              }
          </Grid>
          }







        </Grid>
      </Container>

    </div>

          )}
    </div>

  );
};

export default MyProfile;
