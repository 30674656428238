import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import Banner from "../components/banner/banner";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DPerson } from "../model";
import { ContextService } from "../context/context-service";

import { BSGAPI } from "../auth/bsg-api";
import MemberCard from "../components/card/member-card";
import Loading from "../components/loading/loading";

export interface Props {
  text: string;
  /**
     * 
    ok: boolean;
    id?: number;  // ? optional
    fn: (bob: string) => string;
    person: Person;
     */
}

const MyCommunity = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  let [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([] as DPerson[]);

  useEffect(() => {
    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        forceUpdate();
        //getMyPayments();
        BSGAPI.getCommunityMembers(
          context.user.cognitoCredentials,
          context.user.cognitoUser
        ).then((data) => {
          setMembers(data as DPerson[]);

          setLoading(false);
        });
      } else {
        /** we allow public access for now - in the future this will be disabled 
        BSGAPI.getCommunityMembers(
          context.user.cognitoCredentials,
          context.user.cognitoUser
        ).then((data) => {
          setMembers(data as DPerson[]);

          setLoading(false);
        });
        **/

        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/community`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        
        history("/sign-in");

      }
    });

    document.title = `${ContextService.SITE_NAME}:My Community`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>My Community: BSG Academy</h1>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h2>Connect with with your peers in our community</h2>
          </Grid>
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="lg">
          {loading ? (
            <div>
              <Loading show={loading} />
            </div>
          ) : (
            <Grid
              container
              spacing={6}
              justifyContent="center"
              alignItems="stretch"
            >
              {members?.map((member, index) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    justifyContent="center"
                  >
                    <MemberCard
                      member={member}
                      key={`member-${index}`}
                    ></MemberCard>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Container>
      </div>
    </div>
  );
};

export default MyCommunity;
