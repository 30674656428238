import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Box, Container, Divider, Grid } from "@mui/material";
import CalendarMonth from "./calendar-month";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { BSGCalendar } from "./bsg-calendar";
import CalendarHour from "./calendar-hour";
import ScheduleDay from "./schedule-day";

const Calendar = (props: any) => {
  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const today = new Date();

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());

  return (
    <div className="calendar-card">
      <Grid container spacing={6} justifyContent="center" alignItems="stretch">
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          justifyContent="center"
          alignContent="center"
        >
          <Box display="flex" width="100%">
            <Box flexGrow={1} alignItems="flex-start">
              <h3 className="secondary-light">
                {year} {BSGCalendar.MONTH[month]}{" "}
              </h3>
            </Box>

            <Box alignItems="flex-end">
              <Button variant="contained" color="secondary" onClick={() => {}}>
                <KeyboardArrowLeftIcon />
                {month > 0
                  ? BSGCalendar.MONTH[month - 1]
                  : BSGCalendar.MONTH[11]}
              </Button>{" "}
              <Button variant="contained" color="secondary" onClick={() => {}}>
                <KeyboardArrowRightIcon />
                {month < 11
                  ? BSGCalendar.MONTH[month + 1]
                  : BSGCalendar.MONTH[0]}
              </Button>
            </Box>
          </Box>

          <Box flexGrow={1} width="100%">
            <CalendarMonth year={year} month={month} />
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          flexDirection="row"
          justifyContent="center"
          alignContent="center"
        >
          <ScheduleDay />
        </Grid>
      </Grid>
    </div>
  );
};

export default Calendar;
