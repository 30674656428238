import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Box, Container, Grid } from "@mui/material";
import Day from "./calendar-day";
import { DCalendarItem, DDay } from "../../model";
import CalendarWeek from "./calendar-week";
import { BSGCalendar } from "./bsg-calendar";

const CalendarMonth = (props: any) => {
  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const year = props.year as number; // full year
  const month = props.month as number; // 0 - 11 : month of year

  const today = new Date();

  const [startDays, setStartDays] = useState([] as Date[]);

  const loadStartDays = () => {
    let firstDayOfMonth = BSGCalendar.current().firstDayOfMonth();
    let weekDay = firstDayOfMonth.getDay();

    let offset = weekDay - 0;

    let firstDayOnCalendar = BSGCalendar.current().firstDayOnCalendar();
    let myDays = [
      firstDayOnCalendar,
      BSGCalendar.current().getDate(firstDayOnCalendar, 7),
      BSGCalendar.current().getDate(firstDayOnCalendar, 7 * 2),
      BSGCalendar.current().getDate(firstDayOnCalendar, 7 * 3),
      BSGCalendar.current().getDate(firstDayOnCalendar, 7 * 4),
    ];
    console.log("first date: " + firstDayOfMonth);
    console.log("Weekday: " + weekDay);
    console.log("first calendar: " + firstDayOnCalendar);

    setStartDays(myDays);
  };

  useEffect(() => {
    loadStartDays();
  }, []);

  return (
    <Box alignItems="flex-end" width="100%">
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          p: 0,
          m: 0,
          bgcolor: "background.paper",
          width: "100%",
          border: "0px solid silver",
        }}
      >
        <div className="day-card">
          <Box>S</Box>
        </div>
        <div className="day-card">
          <Box>M</Box>
        </div>
        <div className="day-card">
          <Box>T</Box>
        </div>
        <div className="day-card">
          <Box>W</Box>
        </div>
        <div className="day-card">
          <Box>R</Box>
        </div>
        <div className="day-card">
          <Box>F</Box>
        </div>
        <div className="day-card">
          <Box>S</Box>
        </div>
      </Box>

      {startDays
        ? startDays.map((date) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                p: 0,
                m: 0,
                bgcolor: "background.paper",
                width: "100%",
                border: "0px solid silver",
              }}
            >
              <CalendarWeek startDate={date} />
            </Box>
          ))
        : ""}
    </Box>
  );
};

export default CalendarMonth;
