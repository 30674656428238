import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { DDay } from "../../model";
import { BSGCalendar } from "./bsg-calendar";
import CalendarHour from "./calendar-hour";
import useDynamicRef from "../../hooks/dynamic-ref";

const ScheduleDay = (props: any) => {
  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const [getRef, setRef] = useDynamicRef();

  //let track = useRef({ top: 0, left: 0 });

  let [track, setTrack] = useState({ top: 0, left: 0 });

  const day = props.day as DDay;

  const today = new Date();

  const [hours, setHours] = useState([] as number[]);

  const loadHours = () => {
    let myHours = [];
    for (let i = 0; i < 24; i++) {
      myHours.push(i);
    }
    setHours(myHours);
  };

  const handleEvent = (event: any) => {
    console.log("handling event ....");
    //console.log("handling event ...." + JSON.stringify(event));
  };

  useEffect(() => {
    loadHours();

    //window.addEventListener("resize", handleEvent);
    //window.addEventListener("click", handleEvent);
  }, []);

  return (
    <div className="schedule-card">
      <div
        style={{
          position: "absolute",
          top: track.top,
          left: track.left,
          padding: "0px",
          margin: "0px",
          border: "5px solid green",
          opacity: 1.0,
          width: "auto",
          alignContent: "center",
          textAlign: "center",
          zIndex: 200,
        }}
      >
        This is a dynamic mouse event test.
      </div>
      <div className="schedule-item-card">This is a test.</div>
      <div>
        Event View Goes here.
        {hours.map((hour, index) => (
          <div
            ref={setRef(`hour-${hour}`) as React.RefObject<HTMLDivElement>}
            onDrag={(event: any) => {
              console.log("drag");
              //handleEvent(event);
            }}
            onClick={(e: any) => {
              console.log("click: (" + e.clientX + "," + e.clientY + ")");
              console.log("click: (" + e.pageX + "," + e.pageY + ")");
              console.log("click: (" + e.screenX + "," + e.screenY + ")");
              //handleEvent(event);
              //track.current.left = e.clientX;
              //track.current.top = e.clientY;
              setTrack({ top: e.pageY, left: e.pageX });
            }}
            onMouseDown={(event: any) => {
              console.log("mouse down");
              //handleEvent(event);
            }}
            onMouseUp={(event: any) => {
              console.log("mouse up");
              //handleEvent(event);
            }}
          >
            <Divider />
            <CalendarHour hour={hour} />
          </div>
        ))}
        <Divider />
      </div>
    </div>
  );
};

export default ScheduleDay;
