import { ContextService } from "../context/context-service";
import {
  DCalendarEventSignup,
  DCognitoCredentials,
  DCognitoUser,
  DLog,
  DLogItem,
  DNameValue,
  DOpportunity,
  DVerification,
} from "../model";


export class BSGAPI2 {
 
  constructor() {}

  static config() {
    //console.log("This is config of BSG API class");
  }

  static listCalendarEvent(group: string) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ group: group }),
      };
      fetch("/api2/list-calendar-event", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // this method requires SMS verification
  static signupCalendarEvent(verification: DVerification, data: DCalendarEventSignup) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          //Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ verification: verification, data: data }),
      };
      fetch("/api2/signup-calendar-event", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  // this method requires IAM Policy  
  static getEvents(credentials: DCognitoCredentials, group: string) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ group: group }),
      };
      fetch("/api2/get-events", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static signUpEvent(credentials: DCognitoCredentials, data: DCalendarEventSignup) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ data: data }),
      };
      fetch("/api2/signup-event", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  
  static getSMSVerificationCode(verification: DVerification) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          //Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ verification: verification }),
      };
      fetch("/api2/get-sms-verification-code", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // get news
  static getNews(credentials: DCognitoCredentials) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({}),
      };
      fetch("/api2/get-news", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

    // get news
    static getBusiness(credentials: DCognitoCredentials, query: any) {
      return new Promise<any>((resolve, reject) => {
        const options = {
          method: "POST",
          headers: {
            "Cache-Control": "No-Cache",
            "Content-Type": "application/json",
            Authorization: `Bearer ${credentials.AccessToken}`,
          },
          body: JSON.stringify({
            query: query
          }),
        };
        fetch("/api2/get-business", options)
          .then((response) => response.json())
          .then((json) => {
            resolve(json); // let client to deal with error/exception
          })
          .catch((err) => {
            //console.log("Error: " + err);
            reject(err);
          });
      });
    }

  // get my ui
  static getUI(credentials: DCognitoCredentials) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({}),
      };
      fetch("/api2/my-ui", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static initUser(credentials: DCognitoCredentials) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({}),
      };
      fetch("/api2/my-init-user", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static myTest(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        }),
      };
      fetch("/api2/my-test", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getMyProfile(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        }),
      };
      fetch("/api2/get-my-profile", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }  


  static getProfile(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        }),
      };
      fetch("/api2/get-profile", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static updateProfile(
    credentials: DCognitoCredentials, 
    profile: any
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",      
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({                     
          profile: profile
        }),
      };
      fetch("/api2/update-profile", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }
  
  static setupFolder(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        
        }),
      };
      fetch("/api2/setup-folder", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getPaymentHistory(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        }),
      };
      fetch("/api2/get-payment-history", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static getBSGContent(
    credentials: DCognitoCredentials
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
        }),
      };
      fetch("/api2/get-bsg-content", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 
  
  static getBSGPersons(
    credentials: DCognitoCredentials, 
    offset: number
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          offset: offset
        }),
      };
      fetch("/api2/get-bsg-persons", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 
  
  static getBSGCompanies(
    credentials: DCognitoCredentials, 
    offset: number
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          offset: offset
        }),
      };
      fetch("/api2/get-bsg-companies", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 
  

  static searchBSGPersonsByKeyword(
    credentials: DCognitoCredentials, 
    keyword: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          keyword: keyword
        }),
      };
      fetch("/api2/search-bsg-persons", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 
  
  static searchBSGCompaniesByKeyword(
    credentials: DCognitoCredentials, 
    keyword: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          keyword: keyword
        }),
      };
      fetch("/api2/search-bsg-companies", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

  static getBSGNetwork(
    credentials: DCognitoCredentials, 
    id: string | null, keyword: string | null
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          id: id, 
          keyword: keyword
        }),
      };
      fetch("/api2/get-bsg-network", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  } 

}
