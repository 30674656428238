import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Box, Container, Grid } from "@mui/material";
import { DPayment } from "../../model";

const PaymentCard = (props: any) => {
  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const payment = props.payment as DPayment;

  return (
    <Card className="payment-card">
      {payment != null ? (
        <>
          <Box>
            <h3 className="center">{payment.date?.split("T")[0]}</h3>
          </Box>
          <Box>
            <h1 className="center secondary-light">${payment.amount}</h1>
          </Box>

          <Box flexGrow={1}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<h3>${payment.note}</h3>`,
              }}
            ></div>
          </Box>

          <Box alignItems="flex-end">
            <CardActions>
              <div className="action-button">
                {props.route ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<ArrowForwardIosOutlinedIcon />}
                    onClick={() => {
                      routeTo(props.route as string);
                    }}
                  >
                    {props.routeText}
                  </Button>
                ) : (
                  ""
                )}

                {props.onClick ? (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      props.onClick();
                    }}
                  >
                    {props.onClickText}
                  </Button>
                ) : (
                  ""
                )}

                {props.linkUrl ? (
                  <a href={props.linkUrl} target="_blank">
                    {props.linkText}
                  </a>
                ) : (
                  ""
                )}
              </div>
            </CardActions>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <h1 className="center warning-dark">
              <ArrowForwardIosOutlinedIcon
                color="warning"
                style={{ fontSize: 64 }}
              />
            </h1>
          </Box>
          <Box>
            <h3 className="center">
              Looks like you haven't made any referral fee.
            </h3>
            <h2 className="center warning-dark">
              Refer a friend today and earn referral commission.
            </h2>
          </Box>
          <Box>
            {props.onClick ? (
              <Button
                variant="contained"
                size="medium"
                color="warning"
                onClick={() => {
                  props.onClick();
                }}
              >
                {props.onClickText}
              </Button>
            ) : (
              ""
            )}
          </Box>
        </>
      )}
    </Card>
  );
};

export default PaymentCard;
