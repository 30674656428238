import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  Avatar,
  Box,
  CardHeader,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import { DLog, DPerson } from "../../model";
import PersonIcon from "@mui/icons-material/Person";
import { BSGAPI } from "../../auth/bsg-api";
import HiringTracker from "../tracker/hiring-tracker";
import { UserContext } from "../../context/user-context";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LanguageIcon from "@mui/icons-material/Language";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAttribute from "./person-attribute";

const CandidateCard = (props: any) => {
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const [candidate, setCandidate] = useState(props.candidate as DPerson);

  const [key, setKey] = useState(new Date().toString());

  useEffect(() => {}, [candidate]);

  return (
    <>
      <Card className="candidate-card" raised>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            backgroundColor: "#FBFBFB",
          }}
        >
          <Box
            flexGrow={1}
            sx={{
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <h6 className="left">[Candidate ID: {candidate.id}]</h6>
          </Box>
          <Box
            sx={{
              justifyContent: "flex-end",
              alignContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <IconButton aria-label="more">
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            backgroundColor: "#FBFBFB",
          }}
        >
          <Box
            sx={{
              justifyContent: "flex-end",
              alignContent: "flex-end",
            }}
          >
            {candidate.icon ? (
              <Avatar
                alt={`${candidate.fname} ${candidate.lname}`}
                src={candidate.icon}
                className="candidate-avatar"
              />
            ) : (
              <Avatar aria-label="person" className="candidate-avatar">
                <PersonIcon color="primary" style={{ fontSize: "60px" }} />
              </Avatar>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            backgroundColor: "#FBFBFB",
          }}
        >
          <Box
            flexGrow={1}
            sx={{
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <h2 className="primary-dark center">
              {candidate.fname} {candidate.lname}
            </h2>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            alignContent: "flex-end",
          }}
        >
          <h4 className="center">{candidate.email}</h4>
          <h4 className="center">{candidate.phone}</h4>
          {candidate.wechat && (
            <h4 className="center">WebChat: {candidate.wechat}</h4>
          )}
          <h5 className="center">Candidate for </h5>
          <h3 className="center">
            {candidate.companyName}
            <br />
            <Chip label={candidate.companyRelationshipNote} color="default" />
          </h3>

          {candidate.linkedin && (
            <a href={candidate.linkedin} target="_blank">
              <LinkedInIcon aria-label="LinkedIn" />{" "}
            </a>
          )}
          {candidate.resume && (
            <a href={candidate.resume} target="_blank">
              <PictureAsPdfIcon aria-label="Resume" />{" "}
            </a>
          )}
          {candidate.web && (
            <a href={candidate.web} target="_blank">
              <LanguageIcon aria-label="WebLink" />{" "}
            </a>
          )}
        </Box>

        <Box flexGrow={1}>
          <h3>{props.children}</h3>
        </Box>

        <Box>
          <PersonAttribute person={candidate.id} type="HIRING" />
        </Box>

        <Box alignItems="flex-end">
          <Box>
            {candidate && (
              <HiringTracker
                candidate={candidate}
                key={`${candidate.companyId}-${candidate.id}-hiring-tracker-${key}`}
              />
            )}
          </Box>
          <CardActions>
            <div className="action-button">
              {props.route ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  startIcon={<ArrowForwardIosOutlinedIcon />}
                  onClick={() => {
                    routeTo(props.route as string);
                  }}
                >
                  {props.routeText}
                </Button>
              ) : (
                ""
              )}

              {props.onClick ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    props.onClick();
                  }}
                >
                  {props.onClickText}
                </Button>
              ) : (
                ""
              )}

              {props.linkUrl ? (
                <a href={props.linkUrl} target="_blank">
                  {props.linkText}
                </a>
              ) : (
                ""
              )}
            </div>
          </CardActions>
        </Box>
      </Card>
    </>
  );
};

export default CandidateCard;
