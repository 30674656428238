import React, { PropsWithChildren, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { DCompany, DContent, DNode, DPerson } from "../../model";
import YouTubeCard from "./youtube-card";
import GoogleSlideCard from "./google-slide-card";
import ImageCard from "./image-card";
import MemberCard from "./member-card";
import CompanyCard from "./company-card";
import { Box, Container, Grid, Modal, TextField } from "@mui/material";
import NodeCard from "./node-card";
import BSGModal from "../modal/bsg-modal";

import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import { AdminAPI2 } from "../../auth/admin-api2";
import { UserContext } from "../../context/user-context";
import { HitTargets, Node, Relationship } from "@neo4j-nvl/base";
import { InteractiveNvlWrapper, MouseEventCallbacks } from "@neo4j-nvl/react";
import Loading from "../loading/loading";
export interface INodePickerField {
  node: DNode | null, 
  onChange: any
}

const NodePicker = (
  { node, 
    onChange
  }: INodePickerField) => 
{


  const { context } = useContext(UserContext);
  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const [myNode, setMyNode] = useState(node); 
  const [loading, setLoading] = useState(false);
  
  const [open, setOpen] = useState(false); 
  const [keyword, setKeyword] = useState(""); 

    // graph nodes and relationships contains UI rendering info (as required by @neo4j-nvl)
    const [graphNodes, setGraphNodes] = useState<Node[]>([])
    const [graphRelationships, setGraphRelationships] = useState<Relationship[]>([]); 
  
    // raw data nodes and relationships contain more business data
    const [nodes, setNodes] = useState<any[]>([])
    const [relationships, setRelationships] = useState<any[]>([]); 
  
    const [record, setRecord] = useState({} as any); 

    const [debug, setDebug] = useState("" as string); 
    
    const clear = () => { 
      setGraphNodes([]);
      setGraphRelationships([]);
      setNodes([]);
      setRelationships([]);
      setRecord({});
    }
  
    const loadNetwork = (id: string | null, keyword: string | null) => { 
      AdminAPI2.adminGetNetwork(
        context.user.cognitoCredentials,
        id, 
        keyword
      ).then((json) => {
  
        console.log(JSON.stringify(json));
        //{"data":null,"status":"unauthorized"}
  
        if(json.status != 'ok') {
          console.log("BAD ..." + json.status); 
          //return;
          history("/home");
        } else {
          console.log("OK ...");
        // TODO - replace concat: check if existing before add node or relationship
        setGraphNodes(graphNodes.concat(...json.data["graph"]["nodes"]));
        setGraphRelationships(graphRelationships.concat(json.data["graph"]["relationships"])); 
  
        setNodes(nodes.concat(json.data["raw"]["nodes"]));
        setRelationships(relationships.concat(json.data["raw"]["relationships"])); 
        setLoading(false);
        }
  
      }).catch((err: any)=> {
          console.log(err);
      });
    }
  
    const getRawNode = (graphNode: Node) => {
      const rawNode: any = nodes.find((n) => n.id == graphNode.id);
      return rawNode; 
    }
  
    const getRawRelationship = (graphRelationship: Relationship) => {
      const rawRelationship: any = relationships.find((n) => n.id = graphRelationship.id && n.from == graphRelationship.from && n.to == graphRelationship.to);
      return rawRelationship; 
    }
  
    const addElements = () => {
      //const newNodes = [...nodes, { id: nodes.length as  }]
      //setNodes(newNodes)
    }
  
    const mouseEventCallbacks: MouseEventCallbacks = {
      onHover: (element: Node | Relationship, hitTargets: HitTargets, evt: MouseEvent) => {
        //console.log('onHover', element, hitTargets, evt); 
      },
      onRelationshipRightClick: (rel: Relationship, hitTargets: HitTargets, evt: MouseEvent) => {
        //console.log('onRelationshipRightClick', rel, hitTargets, evt)
      },
      onNodeClick: (node: Node, hitTargets: HitTargets, evt: MouseEvent) => {
        //console.log('onNodeClick', node, hitTargets, evt); 
        const rawNode = getRawNode(node); 
        setRecord(rawNode); 
        //setDebug(rawNode);
      },
      onNodeRightClick: (node: Node, hitTargets: HitTargets, evt: MouseEvent) => {
        //console.log('onNodeRightClick', node, hitTargets, evt); 
      },
      onNodeDoubleClick: (node: Node, hitTargets: HitTargets, evt: MouseEvent) => {
        //console.log('onNodeDoubleClick', node, hitTargets, evt); 
        console.log(JSON.stringify(node));
        const rawNode: any = getRawNode(node); 
        console.log(JSON.stringify(rawNode));
        loadNetwork(rawNode.value.id, null); 
      },
      onRelationshipClick: (rel: Relationship, hitTargets: HitTargets, evt: MouseEvent) => {
        //console.log('onRelationshipClick', rel, hitTargets, evt); 
        const rawRelationship: any = getRawRelationship(rel);
        //setDebug(rawRelationship);
        
      },
      onRelationshipDoubleClick: (rel: Relationship, hitTargets: HitTargets, evt: MouseEvent) => {
        //console.log('onRelationshipDoubleClick', rel, hitTargets, evt); 
  
      },
      onCanvasClick: (evt: MouseEvent) => { console.log('onCanvasClick', evt); 
      },
      onCanvasDoubleClick: (evt: MouseEvent) => { console.log('onCanvasDoubleClick', evt); 
      },
      onCanvasRightClick: (evt: MouseEvent) => { console.log('onCanvasRightClick', evt); 
      },
      onDrag: (nodes: Node[]) => { console.log('onDrag', nodes); 
      },
      //onPan: (evt: MouseEvent) => { console.log('onPan', evt),
      onPan: true, 
      onZoom: (zoomLevel: number) => {
        //console.log('onZoom', zoomLevel); 
      }
    }
  

  return (<>
  
  { node != null ? 
<>
  <Button onClick={() => setOpen(true)}>Open Modal</Button>
  </>
  : <>
  
  <Card>
    Empty Card - Here is when

    <Button onClick={() => setOpen(true)}>Open Modal</Button>
  </Card>
  </> 
}
  


<BSGModal
                title="Refer a friend"
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              >
                <Container maxWidth="lg">
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    alignContent="center"
                  >
                    <Grid container item xs={12} justifyContent="center">
                      <h2 className="secondary-light">
                        Choose Node
                      </h2>

                      


                      <Container maxWidth="xl">

<Grid
  container
  rowSpacing={2}
  columnSpacing={2}
  direction="row"
  justifyContent="center"
  justifyItems="center"
  alignItems="stretch"
  alignContent="center"
      textAlign="center">

  {loading ? (
    <Grid
        item
        xs={12}
        justifyContent="flex-start"
        alignItems="flex-start">
      <Loading show={loading} />
    </Grid>
  ) : (
  <>
    <Grid
      item
      xs={12}
      md={8}
      lg={8}
      justifyContent="center"
  justifyItems="center"
      alignItems="center">

<Box sx={{ flexGrow: 1, justifyContent: "flex-end", alignContent: "flex-end", backgroundColor: "white"}}>
  <PeopleIcon color="secondary" /> 
        <BusinessIcon color="warning" /> 
        <TextField
            required
            id="keyword"
            color="secondary"
            variant="outlined"
            label="Search by keyword"
            autoComplete="keyword"
            defaultValue={""}
            value={keyword}
            onKeyDown={ (e: any) => {
              if(e.key === 'Enter') {
                e.preventDefault();
                //submitSearch();
                loadNetwork(null, keyword); 
              }
            }}
            onChange={(e) => {
              //setUser({ ...user, username: e.target.value });
              setKeyword(e.target.value); 
            }}
          />                            <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              //submitSearch(); 
              loadNetwork(null, keyword)
            }}
          >
            Searh
          </Button> 
          <Button
          key="clear-button"
            variant="outlined"
            color="info"
            onClick={() => {
              clear();
            }}
          >
            Clear
          </Button>
  </Box>


{ nodes.length == 0?  
      <h3 className="center warning-dark">
        No records found. Use some meaning keyword to search. 
      </h3>: ""
}
</Grid>

  <Grid
      item
      xs={12}
      md={4}
      lg={4}
      justifyContent="center"
      alignItems="center"
    >
</Grid>

<Grid
      item
      xs={12}
      md={8}
      lg={8}
      justifyContent="center"
  justifyItems="center"
      alignItems="center"
      textAlign="center"
      >
      <InteractiveNvlWrapper 
          nvlOptions={{ useWebGL: false, initialZoom: 1.0}}
        nodes={graphNodes} 
        rels={graphRelationships} 
        mouseEventCallbacks={mouseEventCallbacks} 
        style={{width: "100%", height: "100%", minWidth: 400, minHeight: 600, padding: 0, margin: 0, border: "1px solid silver"}}
      />
    </Grid>

    <Grid
      item
      xs={12}
      md={4}
      lg={4}
      justifyContent="center"
      justifyItems="center"
      alignItems="center"
      textAlign="center"
      >
        <div
        style={{padding: 0, width: "100%"}}>

          <NodeCard node={record} onClick={()=>{ setMyNode(record); onChange(record); setOpen(false); }} onClickText="View/Edit"/>

    { record.type == 'User' ? 
        <MemberCard member={record.value as DPerson} onClick={()=>{ setMyNode(record); onChange(record); setOpen(false); }} onClickText="View/Edit"></MemberCard>:""
    }

    { record.type == 'Person'? 
        <MemberCard member={record.value as DPerson} onClick={()=>{ setMyNode(record); onChange(record); setOpen(false); }} onClickText="View/Edit"></MemberCard>:""
    }

    { record.type == 'Company'? 
        <CompanyCard company={record.value as DCompany} onClick={()=>{ setMyNode(record); onChange(record); setOpen(false); }} onClickText="View/Edit"></CompanyCard>:""}


      {  JSON.stringify(debug, null, 2) }
      </div>

    </Grid>
  </>
)}
</Grid>   

</Container>




                    </Grid>

                  </Grid>
                </Container>
              </BSGModal>

  </>)

};

export default NodePicker;
