import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import Banner from "../components/banner/banner";
import {
  Button,
  ButtonGroup,
  Container,
  Fab,
  Fade,
  Grid,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material";
import { API } from "aws-amplify";
import { useNavigate, useParams } from "react-router";
import { DLog, DPerson, DUser } from "../model";
import { ContextService } from "../context/context-service";
import CandidateCard from "../components/card/candidate-card";
import { BSGAPI } from "../auth/bsg-api";
import LogCard from "../components/card/log-card";
import Loading from "../components/loading/loading";
import HiringTracker from "../components/tracker/hiring-tracker";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

interface Person {
  firstname: string;
  lastname: string;
}

export interface Props {
  text: string;
}

const Candidate = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  //const [candidate, setCandidate] = useState(null as unknown as DPerson);
  //const [logs, setLogs] = useState([] as DLog[]);
  //const [log, setLog] = useState(null as unknown as DLog);

  let tracker = useRef({
    candidate: props.candidate as DPerson,
    log: null as unknown as DLog,
    logs: [] as DLog[],
  });

  let [loading, setLoading] = useState(true);
  let [loading2, setLoading2] = useState(true);

  let [key, setKey] = useState(new Date().toString());

  //const id = props.match.params.id;
  //const companyId = props.match.params.company;

  const { companyId, id } = useParams();

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const [subtype, setSubtype] = useState("ALL");

  const resetLog = (candidate: DPerson) => {
    tracker.current.log = {
      id: -1,
      type: BSGAPI.JOB_APPLICATION_TYPE,
      subtype: subtype,
      company: candidate.companyId,
      person: candidate.id as string,
      updatePerson: context.user.person?.fname,
      logDay: new Date(),
      note: "",
    };
    setKey(new Date().toString());
    //console.log("Log to be saved 000: " + JSON.stringify(log));
  };

  const onSave = (savedLog: DLog, isNew: boolean) => {
    // dispatch event for a updated log (to HiringTracker)

    //console.log("SavedLog: " + JSON.stringify(savedLog));
    //let isNew = savedLog.id != log.id;
    if (isNew) {
      //setLogs([savedLog, ...logs]);
      tracker.current.logs = [savedLog, ...tracker.current.logs];
      resetLog(tracker.current.candidate);
      setKey(new Date().toString());
    } else {
      setKey(new Date().toString());
    }

    // set page's subtype select to the last edited log
    if (savedLog != null && savedLog.subtype != subtype) {
      setSubtype(savedLog.subtype as string);
      reload(savedLog.subtype as string);
    }

    //let customEvent = new CustomEvent("candidate:log:updated", {
    //  detail: { log: savedLog, isNew: isNew },
    //});
    //document.dispatchEvent(customEvent);

    setShowMessage(true);
    setMessage("Saved successfully");
  };

  const onDelete = (deletedObj: DLog) => {
    //console.log("Setting updated logs ...." + deletedObj.id);
    if (deletedObj.id != null && deletedObj.id > -1) {
      tracker.current.logs = tracker.current.logs.filter(function (obj) {
        return obj.id !== deletedObj.id;
      });

      setKey(new Date().toString());
      // send event, when log is updated
      const customEvent = new CustomEvent("candidate:log:deleted", {
        detail: { log: deletedObj },
      });
      document.dispatchEvent(customEvent);

      setShowMessage(true);
      setMessage("Deleted successfully");
    } else {
    }
  };

  const onUpdate = () => {};

  const reload = (subtype: string) => {
    //context.getCognitoJWT().then((jwt) => {
    //  console.log("Cognito JWT Token: " + jwt);
    //});

    if (tracker.current.candidate) {
      setLoading(true);
      BSGAPI.getCandidateLogs(
        context.user.cognitoCredentials,
        context.user.cognitoUser,
        tracker.current.candidate.companyId as string,
        id as string,
        subtype
      ).then((response2: any) => {
        if (response2.status == "ok") {
          tracker.current.logs = response2.data as DLog[];

          setKey(new Date().toString());
          setLoading(false);
        } else {
        }
      });
    }
  };

  const renderIcon = (subtype: string, color: any) => {
    switch (subtype) {
      case "Strength":
        return <ArrowCircleUpIcon fontSize="large" color={color} />;
      case "Mild Strength":
        return <ArrowCircleUpIcon fontSize="small" color={color} />;
      case "Mixed":
        return <CircleIcon fontSize="medium" color={color} />;
      case "Mild Concern":
        return <ArrowCircleDownIcon fontSize="small" color={color} />;
      case "Concern":
        return <ArrowCircleDownIcon fontSize="large" color={color} />;
      default:
        return <CircleOutlinedIcon fontSize="medium" color={color} />;
    }
  };

  useEffect(() => {
    context.init().then(() => {
      if (context.user.cognitoUser?.Username) {
        forceUpdate();
        BSGAPI.getCandidate(
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          companyId as string,
          id as string
        ).then((response: any) => {
          console.log("response: " + JSON.stringify(response));
          if (response.status == "ok") {
            //setCandidate(response.data as DPerson);

            console.log("response: " + JSON.stringify(response));
            tracker.current.candidate = response.data as DPerson;
            setLoading2(false);
            resetLog(response.data as DPerson);
            BSGAPI.getCandidateLogs(
              context.user.cognitoCredentials,
              context.user.cognitoUser,
              companyId as string, //(response.data as DPerson).companyId as string,
              id as string,
              subtype as string
            ).then((response2: any) => {
              console.log("response2: " + JSON.stringify(response2));
              if (response2.status == "ok") {
                tracker.current.logs = response2.data as DLog[];

                setKey(new Date().toString());
                setLoading(false);
              } else {
              }
            });
          } else if (response.status == "token-expired") {
            setError("Your session has expired. Please login again.");
            setShowError(true);
            setTimeout(() => {
              history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            }, 3000);
          } else {
            // TODO: exception handling ..

            setError("Some error occurred. Please try again later.");
            setShowError(true);
          }
        });
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/candidates`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });
    document.title = `${ContextService.SITE_NAME}:Candidate`;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.addEventListener("candidate:tracker:updated", function (e: any) {
      // when tracker updated, we refresh as well.
      if (e.detail.isNew) {
        //console.log("adding new log to the top of the list: " + logs.length);
        //setLogs([e.detail.log, ...logs]);
        tracker.current.logs = [e.detail.log, ...tracker.current.logs];
        setKey(new Date().toString());
      } else {
        let newObjects = tracker.current.logs.map((obj) => {
          return obj.id == e.detail.log.id ? e.detail.log : obj;
        });
        //setLogs(newObjects);
        tracker.current.logs = newObjects;
        setKey(new Date().toString());
      }
    });

    return () => {
      document.removeEventListener("candidate:tracker:updated", () => {});
    };
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>Candidate Detail</h1>
          </Grid>
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignContent="center"
            >
              {showError && (
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  onClose={() => {
                    setShowError(false);
                  }}
                  severity="error"
                >
                  {error}
                </MuiAlert>
              )}
            </Grid>

            <Grid container item xs={12} justifyContent="center">
              {loading2 ? (
                <Loading show={loading2} />
              ) : (
                <CandidateCard
                  candidate={tracker.current.candidate}
                  onUpdate={onUpdate}
                ></CandidateCard>
              )}
            </Grid>

            <Grid container item rowSpacing={2} xs={12} justifyContent="center">
              {BSGAPI.JOB_APPLICATION_ASSESSMENT_SUBTYPES.map((type) => {
                return (
                  <Fab
                    variant="extended"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setSubtype(type.value as string);
                      reload(type.value as string);
                    }}
                  >
                    {renderIcon(type.value as string, type.color)}
                    <h6>{type.name}</h6>
                  </Fab>
                );
              })}
              <Fab
                variant="extended"
                color="primary"
                size="small"
                onClick={() => {
                  setSubtype("ALL");
                  reload("ALL");
                }}
              >
                <CategoryOutlinedIcon fontSize="medium" color="inherit" />
                All
              </Fab>
              <Grid container item xs={12}>
                <h5 className="center">Only the last 100 notes are shown.</h5>
                {tracker.current.log && (
                  <LogCard
                    key={`log-card-{key}-0`}
                    log={tracker.current.log}
                    onSave={onSave}
                  />
                )}
                <h3 className="center">
                  <Loading show={loading} />
                </h3>
              </Grid>
              {tracker.current.logs.map((log, index) => {
                return (
                  <Grid key={`log-grid-${index}`} container item xs={12}>
                    <LogCard
                      log={log}
                      key={`${key}-log-card-${index}`}
                      onDelete={onDelete}
                    />
                  </Grid>
                );
              })}
              <Snackbar
                open={showMessage}
                autoHideDuration={6000}
                onClose={() => {
                  setShowMessage(false);
                }}
              >
                <MuiAlert
                  onClose={() => {
                    setShowMessage(false);
                  }}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {message}
                </MuiAlert>
              </Snackbar>
              <Grid container item xs={12}></Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Candidate;
