import * as React from "react";
import {
  useTheme,
  useMediaQuery,
  Stack,
  Paper,
  Divider,
  Fab,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { BSGAPI } from "../../auth/bsg-api";
import { DLog, DMilestone, DMilestoneMeta } from "../../model";
import { render } from "@testing-library/react";

import Popover from "@mui/material/Popover";
import BRichTextField from "../form/rich-text-field";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user-context";

import HeadphonesIcon from "@mui/icons-material/Headphones";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AlbumIcon from "@mui/icons-material/Album";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SaveIcon from "@mui/icons-material/Save";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import "./style.scss";
import HtmlTooltip from "../tooltip/tool-tip";

export default function MilestoneItem(props: any) {
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [item, setItem] = useState(props.item as DLog);

  let [values, setValues] = useState([] as string[]);

  const onChangeAction = props.onChange as any;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const [open, setOpen] = useState(false);
  const id = open ? "simple-popover" : undefined;

  const saveAction = props.onSave as any;

  let [key, setKey] = useState(new Date().toString());

  const save = () => {
    return new Promise<DLog>((resolve, reject) => {
      if (item.id == null || item.id == -1) {
        //setLogs(logs.push(log));
        //console.log("update person: " + context.user.person?.id);
        //console.log("candidate company: " + candidate.companyId);

        item.type = item.meta_type;
        item.subtype = "";
        //item.company = candidate.companyId;
        //item.person = candidate.id;
        item.updatePerson = context.user.person?.id;
        item.logDay = new Date();
        item.milestone_code = item.meta_code;
        //setLogs([log]);
        //console.log("Do something ..." + JSON.stringify(item));
        //setLogs([...logs, log]);
        /**/
        BSGAPI.addCandidateLog(
          item.person as string,
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          item
        ).then((response: any) => {
          if (response.status == "ok") {
            //setLogs([response.data, ...logs]);
            //setLogs([...logs, response]);
            //resetLog();
            resolve(response.data as DLog);
          } else {
            reject(null);
          }
          //resolve(log);
        });
      } else {
        //console.log("SaveMe: " + item.id);
        //setObjects(...objects, iot);
        //addIoT(iot);
        /**/
        item.type = item.meta_type;
        //item.company = candidate.companyId;
        //item.person = candidate.id;
        item.updatePerson = context.user.person?.id;
        item.logDay = new Date();
        item.milestone_code = item.meta_code;
        BSGAPI.updateCandidateLog(
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          item
        ).then((response: any) => {
          if (response.status == "ok") {
            resolve(response.data as DLog);
          } else {
            reject(null);
          }
        });
      }
    });
  };

  const handleAction = () => {
    //console.log("Log to be saved: " + JSON.stringify(item));

    save()
      .then((savedLog: DLog) => {
        // parent handles newly created object
        // we simply reset:
        //if (isNew) {
        //if (log.id == -1) {
        //}
        if (saveAction) {
          saveAction(savedLog, savedLog.id != item.id);
          //setItem(savedLog);
        }

        setOpen(false);

        setKey(new Date().toString());
      })
      .catch((err: any) => {
        setOpen(false);
      });
  };

  useEffect(() => {
    //console.log("Item: " + JSON.stringify(item));
    let milestone = BSGAPI.JOB_APPLICATION_MILESTONES.find(
      (milestone) => milestone.code == item.meta_code
    );
    if (milestone != null) {
      setValues(milestone.values);
    }
  }, []);

  const renderIcon = () => {
    switch (item.milestone_value) {
      case "Incline to hire":
        return <ThumbUpIcon color="success" />;
      case "Incline to not hire":
        return <ThumbDownIcon color="error" />;
      case "Not sure yet":
        return <CircleOutlinedIcon color="primary" />;
      default:
        return <CircleOutlinedIcon color="info" />;
    }
  };

  const render = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={(e) => {
          handleClick(e);
        }}
      >
        {renderIcon()} <h6>{item.meta_code}</h6>
      </Button>
    );
  };

  return (
    <div className="center milestone">
      <HtmlTooltip
        placement="bottom"
        arrow
        title={
          <React.Fragment>
            <h5>
              by {item.updatePersonName} from {item.updatePersonCompany}
            </h5>
            <h5 className="center secondary-light">
              <b>{item.meta_description}</b>
            </h5>
            <h5 className="center">{item.milestone_value}</h5>
            <h5>
              Note: <em>{item.note}</em>
            </h5>
          </React.Fragment>
        }
      >
        {render()}
      </HtmlTooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <RadioGroup
          aria-label="gender"
          name="controlled-radio-buttons-group"
          value={item.milestone_value}
          onChange={(e: any) => {
            setItem({ ...item, milestone_value: e.target.value });
            //handleAction();
            if (onChangeAction) {
              onChangeAction(e);
            }
          }}
        >
          <h5>{item.meta_description}</h5>
          {values.map((value) => {
            return (
              <>
                <FormControlLabel
                  value={value}
                  control={<Radio color="secondary" size="medium" />}
                  label={value}
                  checked={item.milestone_value == value}
                />
              </>
            );
          })}
        </RadioGroup>

        <TextField
          fullWidth
          rows={3}
          label="Note"
          id="log-note"
          name="log-note"
          value={item?.note}
          multiline={true}
          onChange={(e: any) => {
            setItem({ ...item, note: e.target.value });
          }}
        />
        <IconButton
          color="secondary"
          aria-label="save"
          component="span"
          onClick={() => {
            handleAction();
            //handleClose();
          }}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          color="secondary"
          aria-label="cancel"
          component="span"
          onClick={() => {
            handleClose();
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </Popover>
    </div>
  );
}
