
const Multiline = (props: any) => {

  const string = props.string as string;


  const multilines = string.split(/\r?\n/) as string[];

  return (
  <>
    { multilines.map((line) => {
        return <div><p>{line}</p></div>
    })}
    </>
)
};

export default Multiline;
